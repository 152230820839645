import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

// importing all the themes
import Theme5 from "../themes/theme5";
import Login1 from "../themes/login1";
import Login2 from "../themes/login2";
import SignUp1 from "../themes/signup1";
import SignUp2 from "../themes/signup2";
import ResetPassword from "../themes/resetPassword";
import ChangePassword from "../themes/changePassword";
import Download from "../themes/download";
import Review from "../themes/review";
import Faq from "../themes/faq";
import NotFound from "../themes/404";
import ComingSoon from "../themes/comingSoon";
import ThankYou from "../themes/thankyou";
import Team from "../themes/team";
import Blog from "../themes/blog";
import BlogLeft from "../themes/blogLeft";
import BlogRight from "../themes/blogRight";
import SingleTeam from "../themes/singleTeam";
import Privacy from "../components/Privacy/Privacy";
import Store from "../components/Store/home/presentation/pages/Store";
import EventJ2c2018 from "../components/Events/j2c/2018/EventJ2c2018";
import Training from "../components/training/Training";
import authenticationSignup from "../components/Authentification/authentication-signup";
import authenticationSignin from "../components/Authentification/authentication-signin";
import authenticationForgotPassword from "../components/Authentification/authentication-forgot-password";
import authenticationResetPassword from "../components/Authentification/authentication-reset-password";
import ProductAll from "../components/Store/ProductAll/presentation/pages/ProductAll";
import CategoryAll from "../components/Store/Category/presentation/pages/CategoryAll";
import OrderTrackingPage from "../components/Store/User/OrderTracking/Presentation/OrderTrackingPage";
import AccountOrders from "../components/Store/User/AccountOrders";
import CheckoutCompletePage from "../components/Store/User/CheckoutComplete/Presentation/CheckoutCompletePage";
import CheckoutReviewPage from "../components/Store/User/CheckoutReview/Presentation/CheckoutReviewPage";
import CheckoutPaymentPage from "../components/Store/User/CheckoutPayment/Presentation/CheckoutPaymentPage";
import CheckoutShippingPage from "../components/Store/User/CheckoutShipping/Presentation/CheckoutShippingPage";
import AccountDetail from "../components/Store/User/AccountDetail";
import ShopCartPage from "../components/Store/User/ShopCart/Presentation/ShopCartPage";
import CheckoutDetailPage from "../components/Store/User/CheckoutDetail/Presentation/CheckoutDetailPage";
import DetailProductsPage from "../components/Store/ProductDetail/DetailProduct";


export default class Routes extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                <Router>
                    <Switch>
                        <Route exact path="/" component={Theme5}/>
                        <Route exact path="/training" component={Training}/>
                        <Route exact path="/login1" component={Login1}/>
                        <Route exact path="/login2" component={Login2}/>
                        <Route exact path="/signup1" component={SignUp1}/>
                        <Route exact path="/signup2" component={SignUp2}/>
                        <Route exact path="/resetPassword" component={ResetPassword}/>
                        <Route exact path="/changePassword" component={ChangePassword}/>
                        <Route exact path="/download" component={Download}/>
                        <Route exact path="/review" component={Review}/>
                        <Route exact path="/faq" component={Faq}/>
                        <Route exact path="/404" component={NotFound}/>
                        <Route exact path="/comingSoon" component={ComingSoon}/>
                        <Route exact path="/thankyou" component={ThankYou}/>
                        <Route exact path="/team" component={Team}/>
                        <Route exact path="/singleTeam" component={SingleTeam}/>
                        <Route exact path="/blog" component={Blog}/>
                        <Route exact path="/privacy" component={Privacy}/>
                        <Route exact path="/blogLeft" component={BlogLeft}/>
                        <Route exact path="/blogRight" component={BlogRight}/>
                        <Route exact path="/store" component={Store}/>
                        <Route exact path="/events-j2c-2018" component={EventJ2c2018}/>
                        <Route exact path="/signup" component={authenticationSignup}/>
                        <Route exact path="/signin" component={authenticationSignin}/>
                        <Route exact path="/ForgotPass" component={authenticationForgotPassword}/>
                        <Route exact path="/ResetPass" component={authenticationResetPassword}/>
                        <Route exact path="/store/all-products" component={ProductAll}/>
                        <Route exact path="/store/detail-product" component={DetailProductsPage}/>
                        <Route exact path="/store/all-categories" component={CategoryAll}/>
                        <Route exact path="/store/user/order-tracking" component={OrderTrackingPage}/>
                        <Route exact path="/account/orders" component={AccountOrders}/>
                        <Route exact path="/account/detail-account" component={AccountDetail}/>
                        <Route exact path="/store/user/checkout-complete" component={CheckoutCompletePage}/>
                        <Route exact path="/store/user/checkout-review" component={CheckoutReviewPage}/>
                        <Route exact path="/store/user/checkout-payment" component={CheckoutPaymentPage}/>
                        <Route exact path="/store/user/checkout-shipping" component={CheckoutShippingPage}/>
                        <Route exact path="/store/user/shop-cart" component={ShopCartPage}/>
                        <Route exact path="/store/user/checkout-detail" component={CheckoutDetailPage}/>

                    </Switch>
                </Router>
            </React.Fragment>
        );
    }
}
