import React from "react";
import NavbarStore from "../../../home/presentation/components/NavbarStore";
import FooterStore from "../../../home/presentation/components/FooterStore";


const CheckoutDetailPage = () => {
    return (
        <React.Fragment>
            <div className="bg-theme bg-theme2" style={{color: 'white'}}>
                <div className="wrapper">
                    <NavbarStore/>
                    <div className="page-wrapper">
                        <div className="page-content">
                            <section className="py-3 border-bottom d-none d-md-flex">
                                <div className="container">
                                    <div className="page-breadcrumb d-flex align-items-center">
                                        <h3 className="breadcrumb-title pe-3">Checkout</h3>
                                        <div className="ms-auto">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb mb-0 p-0">
                                                    <li className="breadcrumb-item"><a href="javascript:;"><i
                                                        className="bx bx-home-alt"></i> Home</a>
                                                    </li>
                                                    <li className="breadcrumb-item"><a href="javascript:;">Shop</a>
                                                    </li>
                                                    <li className="breadcrumb-item active"
                                                        aria-current="page">Checkout
                                                    </li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="py-4">
                                <div className="container">
                                    <div className="shop-cart">
                                        <div className="row">
                                            <div className="col-12 col-xl-8">
                                                <div className="checkout-details">
                                                    <div className="card bg-transparent rounded-0 shadow-none">
                                                        <div className="card-body">
                                                            <div className="steps steps-light">
                                                                <a className="step-item active" href="shop-cart.html">
                                                                    <div className="step-progress"><span
                                                                        className="step-count">1</span>
                                                                    </div>
                                                                    <div className="step-label"><i
                                                                        className='bx bx-cart'></i>Cart
                                                                    </div>
                                                                </a>
                                                                <a className="step-item active current"
                                                                   href="checkout-details.html">
                                                                    <div className="step-progress"><span
                                                                        className="step-count">2</span>
                                                                    </div>
                                                                    <div className="step-label"><i
                                                                        className='bx bx-user-circle'></i>Details
                                                                    </div>
                                                                </a>
                                                                <a className="step-item" href="checkout-shipping.html">
                                                                    <div className="step-progress"><span
                                                                        className="step-count">3</span>
                                                                    </div>
                                                                    <div className="step-label"><i
                                                                        className='bx bx-cube'></i>Shipping
                                                                    </div>
                                                                </a>
                                                                <a className="step-item" href="checkout-payment.html">
                                                                    <div className="step-progress"><span
                                                                        className="step-count">4</span>
                                                                    </div>
                                                                    <div className="step-label"><i
                                                                        className='bx bx-credit-card'></i>Payment
                                                                    </div>
                                                                </a>
                                                                <a className="step-item" href="checkout-review.html">
                                                                    <div className="step-progress"><span
                                                                        className="step-count">5</span>
                                                                    </div>
                                                                    <div className="step-label"><i
                                                                        className='bx bx-check-circle'></i>Review
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card rounded-0">
                                                        <div className="card-body">
                                                            <div className="d-flex align-items-center">
                                                                <div className="">
                                                                    <img src="assets/images/avatars/avatar-1.png"
                                                                         width="90" alt=""
                                                                         className="rounded-circle p-1 border"/>
                                                                </div>
                                                                <div className="ms-2">
                                                                    <h6 className="mb-0">Jhon Michle</h6>
                                                                    <p className="mb-0">michle@example.com</p>
                                                                </div>
                                                                <div className="ms-auto"><a href="javascript:;"
                                                                                            className="btn btn-light btn-ecomm"><i
                                                                    className='bx bx-edit'></i> Edit Profile</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card rounded-0">
                                                        <div className="card-body">
                                                            <div className="border p-3">
                                                                <h2 className="h5 mb-0">Shipping Address</h2>
                                                                <div className="my-3 border-bottom"></div>
                                                                <div className="form-body">
                                                                    <form className="row g-3">
                                                                        <div className="col-md-6">
                                                                            <label className="form-label">First
                                                                                Name</label>
                                                                            <input type="text"
                                                                                   className="form-control rounded-0"/>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label className="form-label">Last
                                                                                Name</label>
                                                                            <input type="text"
                                                                                   className="form-control rounded-0"/>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label className="form-label">E-mail
                                                                                id</label>
                                                                            <input type="text"
                                                                                   className="form-control rounded-0"/>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label className="form-label">Phone
                                                                                Number</label>
                                                                            <input type="text"
                                                                                   className="form-control rounded-0"/>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label
                                                                                className="form-label">Company</label>
                                                                            <input type="text"
                                                                                   className="form-control rounded-0"/>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label
                                                                                className="form-label">State/Province</label>
                                                                            <select className="form-select rounded-0">
                                                                                <option>United Kingdom</option>
                                                                                <option>California</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label className="form-label">Zip/Postal
                                                                                Code</label>
                                                                            <input type="text"
                                                                                   className="form-control rounded-0"/>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label
                                                                                className="form-label">Country</label>
                                                                            <select className="form-select rounded-0">
                                                                                <option>United States</option>
                                                                                <option>India</option>
                                                                                <option>China</option>
                                                                                <option>Turkey</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label className="form-label">Address
                                                                                1</label>
                                                                            <textarea
                                                                                className="form-control rounded-0"></textarea>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label className="form-label">Address
                                                                                2</label>
                                                                            <textarea
                                                                                className="form-control rounded-0"></textarea>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <h6 className="mb-0 h5">Billing Address</h6>
                                                                            <div className="my-3 border-bottom"></div>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input"
                                                                                       type="checkbox" id="gridCheck"
                                                                                       checked/>
                                                                                <label className="form-check-label"
                                                                                       htmlFor="gridCheck">Same as
                                                                                    shipping address</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="d-grid"><a
                                                                                href="javascript:;"
                                                                                className="btn btn-light btn-ecomm"><i
                                                                                className='bx bx-chevron-left'></i>Back
                                                                                to Cart</a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="d-grid"><a
                                                                                href="javascript:;"
                                                                                className="btn btn-white btn-ecomm">Proceed
                                                                                to Checkout<i
                                                                                    className='bx bx-chevron-right'></i></a>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-xl-4">
                                                <div className="order-summary">
                                                    <div className="card rounded-0">
                                                        <div className="card-body">
                                                            <div
                                                                className="card rounded-0 border bg-transparent shadow-none">
                                                                <div className="card-body">
                                                                    <p className="fs-5 text-white">Apply Discount
                                                                        Code</p>
                                                                    <div className="input-group">
                                                                        <input type="text"
                                                                               className="form-control rounded-0"
                                                                               placeholder="Enter discount code"/>
                                                                        <button className="btn btn-light btn-ecomm"
                                                                                type="button">Apply Discount
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="card rounded-0 border bg-transparent shadow-none">
                                                                <div className="card-body">
                                                                    <p className="fs-5 text-white">Order summary</p>
                                                                    <div className="my-3 border-top"></div>
                                                                    <div className="d-flex align-items-center">
                                                                        <a className="d-block flex-shrink-0"
                                                                           href="javascript:;">
                                                                            <img src="assets/images/products/01.png"
                                                                                 width="75" alt="Product"/>
                                                                        </a>
                                                                        <div className="ps-2">
                                                                            <h6 className="mb-1"><a href="javascript:;">White
                                                                                Polo T-Shirt</a></h6>
                                                                            <div className="widget-product-meta"><span
                                                                                className="me-2">$19.<small>00</small></span><span
                                                                                className="">x 1</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="my-3 border-top"></div>
                                                                    <div className="d-flex align-items-center">
                                                                        <a className="d-block flex-shrink-0"
                                                                           href="javascript:;">
                                                                            <img src="assets/images/products/17.png"
                                                                                 width="75" alt="Product"/>
                                                                        </a>
                                                                        <div className="ps-2">
                                                                            <h6 className="mb-1"><a href="javascript:;">Fancy
                                                                                Red Sneakers</a></h6>
                                                                            <div className="widget-product-meta"><span
                                                                                className="me-2">$16.<small>00</small></span><span
                                                                                className="">x 2</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="my-3 border-top"></div>
                                                                    <div className="d-flex align-items-center">
                                                                        <a className="d-block flex-shrink-0"
                                                                           href="javascript:;">
                                                                            <img src="assets/images/products/04.png"
                                                                                 width="75" alt="Product"/>
                                                                        </a>
                                                                        <div className="ps-2">
                                                                            <h6 className="mb-1"><a href="javascript:;">Yellow
                                                                                Shine Blazer</a></h6>
                                                                            <div className="widget-product-meta"><span
                                                                                className="me-2">$22.<small>00</small></span><span
                                                                                className="">x 1</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="my-3 border-top"></div>
                                                                    <div className="d-flex align-items-center">
                                                                        <a className="d-block flex-shrink-0"
                                                                           href="javascript:;">
                                                                            <img src="assets/images/products/09.png"
                                                                                 width="75" alt="Product"/>
                                                                        </a>
                                                                        <div className="ps-2">
                                                                            <h6 className="mb-1"><a href="javascript:;">Men
                                                                                Black Hat Cap</a></h6>
                                                                            <div className="widget-product-meta"><span
                                                                                className="me-2">$14.<small>00</small></span><span
                                                                                className="">x 1</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="card rounded-0 border bg-transparent mb-0 shadow-none">
                                                                <div className="card-body">
                                                                    <p className="mb-2">Subtotal: <span
                                                                        className="float-end">$198.00</span>
                                                                    </p>
                                                                    <p className="mb-2">Shipping: <span
                                                                        className="float-end">--</span>
                                                                    </p>
                                                                    <p className="mb-2">Taxes: <span
                                                                        className="float-end">$14.00</span>
                                                                    </p>
                                                                    <p className="mb-0">Discount: <span
                                                                        className="float-end">--</span>
                                                                    </p>
                                                                    <div className="my-3 border-top"></div>
                                                                    <h5 className="mb-0">Order Total: <span
                                                                        className="float-end">212.00</span></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                        </div>
                    </div>
                    <FooterStore/>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CheckoutDetailPage;