import React, { useEffect, useState } from "react";
import { db } from "../../../../../firebase/firebase";
import { productConverter } from "../../../../../models/Product";
import NavbarStore from "../../../home/presentation/components/NavbarStore";
import FooterStore from "../../../home/presentation/components/FooterStore";

const ProductAll = () => {

    const [product, setProduct] = useState([]);

    const fetchProduct = async () => {
        await db.collection("products")
            .withConverter(productConverter)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    console.log(data);
                    console.log(doc.id);
                    const response = db.collection("ProductPicture").where("productId", "==", doc.id).get();
                    response.then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            console.log(doc.data());
                            const item = doc.data()
                            data.picture = item["urlPicture"];
                        });
                        setProduct(arr => [...arr, data])
                    })
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
    }

    useEffect(async () => {
        await fetchProduct();
    }, [])

    return (
        <React.Fragment>
            <div className="bg-theme bg-theme2" style={{ color: 'white' }}>
                <div className="wrapper">
                    <NavbarStore />
                    <div className="page-wrapper">
                        <div className="page-content">
                            <section className="py-3 border-bottom d-none d-md-flex">
                                <div className="container">
                                    <div className="page-breadcrumb d-flex align-items-center">
                                        <h3 className="breadcrumb-title pe-3">Nos articles</h3>
                                        <div className="ms-auto">
                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb mb-0 p-0">
                                                    <li className="breadcrumb-item"><a href="javascript:;"><i
                                                        className="bx bx-home-alt"></i> Accueil</a>
                                                    </li>
                                                    <li className="breadcrumb-item"><a href="javascript:;">Nos
                                                        articles</a>
                                                    </li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="py-4">
                                <div className="container">
                                    <div className="toolbox d-lg-flex align-items-center mb-3 gap-2 p-3 bg-dark-1">
                                        <div className="d-flex flex-wrap flex-grow-1 gap-1">
                                            <div className="">
                                                <select className="form-select rounded-0">
                                                    <option selected="selected">Taille</option>
                                                    <option>Small</option>
                                                    <option>Small</option>
                                                    <option>Small</option>
                                                    <option>Extra Large</option>
                                                </select>
                                            </div>
                                            <div className="">
                                                <select className="form-select rounded-0">
                                                    <option selected="selected">Couleur</option>
                                                    <option>Red</option>
                                                    <option>Yellow</option>
                                                    <option>Black</option>
                                                    <option>White</option>
                                                    <option>Green</option>
                                                    <option>Blue</option>
                                                </select>
                                            </div>
                                            <div className="">
                                                <select className="form-select rounded-0">
                                                    <option selected="selected">Prix</option>
                                                    <option>3000 à 5000 XOF</option>
                                                    <option>5000 à 10000 XOF</option>
                                                    <option>10000 à 20000</option>
                                                    <option>plus de 20000 XOF</option>
                                                </select>
                                            </div>
                                            <div className="">
                                                <button type="button"
                                                    className="btn btn-light rounded-0 text-uppercase">Recherche
                                                </button>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            <div className="d-flex align-items-center flex-nowrap">
                                                <p className="mb-0 font-13 text-nowrap text-white">Voir:</p>
                                                <select className="form-select ms-3 rounded-0">
                                                    <option>9</option>
                                                    <option>12</option>
                                                    <option>16</option>
                                                    <option>20</option>
                                                    <option>50</option>
                                                    <option>100</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="product-wrapper">
                                        <div className="row">
                                            <div className="col-12 col-xl-9">
                                                {product.map((item, i) => (
                                                    <div className="product-grid" key={i}>
                                                        <div className="card rounded-0 product-card">
                                                            <div
                                                                className="d-flex align-items-center justify-content-end gap-3 position-absolute end-0 top-0 m-3">
                                                                <a href="javascript:;">
                                                                    <div className="product-compare"><span><i
                                                                        className="bx bx-git-compare"></i> Compare</span>
                                                                    </div>
                                                                </a>
                                                                <a href="javascript:;">
                                                                    <div className="product-wishlist"><i
                                                                        className="bx bx-heart"></i>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div className="row g-0">
                                                                <div className="col-md-4">
                                                                    <img height={150} width={150} src={item.picture}
                                                                         className="img-fluid"
                                                                        alt="..." />
                                                                </div>
                                                                <div className="col-md-8">
                                                                    <div className="card-body">
                                                                        <div className="product-info">
                                                                            <a href="javascript:;">
                                                                                <p className="product-catergory font-13 mb-1">Catergory
                                                                                    Name</p>
                                                                            </a>
                                                                            <a href="javascript:;">
                                                                                <h6 className="product-name mb-2">{item.title}</h6>
                                                                            </a>
                                                                            <p className="card-text">{item.summary}</p>
                                                                            <div className="d-flex align-items-center">
                                                                                <div
                                                                                    className="mb-1 product-price"><span
                                                                                        className="me-1 text-decoration-line-through">$99.00</span>
                                                                                    <span
                                                                                        className="text-white fs-5">$49.00</span>
                                                                                </div>
                                                                                <div className="cursor-pointer ms-auto">
                                                                                    <i
                                                                                        className="bx bxs-star text-white"></i>
                                                                                    <i className="bx bxs-star text-white"></i>
                                                                                    <i className="bx bxs-star text-white"></i>
                                                                                    <i className="bx bxs-star text-white"></i>
                                                                                    <i className="bx bxs-star text-white"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div className="product-action mt-2">
                                                                                <div className="d-flex gap-2">
                                                                                    <a href="javascript:;"
                                                                                        className="btn btn-white btn-ecomm">
                                                                                        <i
                                                                                            className="bx bxs-cart-add"></i>Add
                                                                                        to
                                                                                        Cart</a>
                                                                                    <a href="/store/detail-product"
                                                                                        className="btn btn-light btn-ecomm"
                                                                                        data-bs-toggle="modal"
                                                                                        data-bs-target="#QuickViewProduct"><i
                                                                                            className="bx bx-zoom-in"></i>Quick
                                                                                        View</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="border-top my-3"></div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="col-12 col-xl-3">
                                                <div className="bestseller-list mb-3 border p-3 bg-dark-1">
                                                    <h6 className="mb-3 text-uppercase">Popular Products</h6>
                                                    <div className="d-flex align-items-center">
                                                        <div className="bottom-product-img">
                                                            <a href="javascript:;">
                                                                <img src="assets/images/products/20.png" width="90"
                                                                    alt="" />
                                                            </a>
                                                        </div>
                                                        <div className="ms-0">
                                                            <h6 className="mb-0 fw-light mb-1">Product Short Name</h6>
                                                            <div className="rating font-12"><i
                                                                className="bx bxs-star text-white"></i>
                                                                <i className="bx bxs-star text-white"></i>
                                                                <i className="bx bxs-star text-white"></i>
                                                                <i className="bx bxs-star text-white"></i>
                                                                <i className="bx bxs-star text-white"></i>
                                                            </div>
                                                            <p className="mb-0 text-white"><strong>$59.00</strong>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </div>
                            </section>
                        </div>
                    </div>
                    <FooterStore />
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProductAll;