import React, {Component} from "react";

import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/HeroSection5";
import PromoSection from "../components/PromoSection/Promo2";
import AboutSection from "../components/AboutUs/AboutUs5";
import Testimonial from "../components/Testimonial";
import TeamMember from "../components/TeamMember";
import ContactSection from "../components/Contact";
import TrustedCompany from "../components/TrustedCompany/TrustedCompany";
import FooterSection from "../components/Footer";
import Actus from "../components/Actus/Actus";

class Theme extends Component {
    render() {
        return (
            <React.Fragment>
                <Header/>
                <div className="main">
                    <HeroSection/>
                    <PromoSection removeTopMargin={true}/>
                    <AboutSection/>
                    <Actus/>
                    <TeamMember/>
                    <Testimonial/>
                    <ContactSection bgColor="white"/>
                    <TrustedCompany bgColor="gray"/>
                </div>
                <FooterSection noSubscription={true}/>
            </React.Fragment>
        );
    }
}

export default Theme;
