class Product {
    constructor(id, categoryId, picture, price, summary, title) {
        this.categoryId = categoryId;
        this.id = id;
        this.picture = picture;
        this.price = price;
        this.summary = summary;
        this.title = title;
    }

    toString() {
        return this.id + ', ' + this.categoryId + ', ' + this.picture + ', ' + this.price + ", " + this.summary + ", " + this.title;
    }
}

//Firestore data converter
export const productConverter = {
    toFireStore: function (product) {
        return {
            id: product.id,
            categoryId: product.categoryId,
            picture: product.picture,
            price: product.price,
            summary: product.summary,
            title: product.title
        }
    },
    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);
        return new Product(data.id, data.categoryId, data.picture, data.price, data.summary, data.title);
    }
};