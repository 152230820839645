import React from "react";
import _data from "../../data";

class TeamMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            teamMember: {}
        };
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
        this.setState({
            teamMember: _data.teamMember
        });
    }

    render() {
        return (
            <React.Fragment>
                <section id="team" className="team-member-section ptb-100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7 col-md-8">
                                <div className="section-heading text-center mb-5">
                                    <span className="text-uppercase color-secondary sub-title">Belle équipe</span>
                                    <h2>Les membres de notre équipe</h2>
                                    <p>
                                        Une équipe bien formée et dynamique prêt à relever tout les défis
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {(this.state.teamMember.members || []).map((member, index) => {
                                return (
                                    <div className="col-lg-3 col-sm-6" key={index}>
                                        <div className="single-team-member position-relative">
                                            <div className="team-image">
                                                <img
                                                    src={member.image}
                                                    alt="Team Members"
                                                    className="img-fluid rounded-circle"
                                                />
                                            </div>
                                            <div
                                                className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                                <h5 className="mb-0">{member.name}</h5>
                                                <h6>
                                                    {member.designation}
                                                </h6>
                                                <ul className="list-inline team-social social-icon mt-4 text-white">
                                                    <li className="list-inline-item">
                                                        <a href={member.twitter}>
                                                            <span className="ti-twitter"></span>
                                                        </a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href={member.linkedin}>
                                                            <span className="ti-linkedin"></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default TeamMember;
