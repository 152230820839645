import React from "react";
import _data from "../../data";

class Actus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            actus: {}
        }
    }

    componentDidMount() {
        this.setState({
            actus: _data.actus
        });
    }

    render() {
        return (
            <React.Fragment>
                <section id="blog" className="our-blog-section ptb-100 primary-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="section-heading mb-5 text-white">
                                    <h2 className="text-white">Nos dernières actualités</h2>
                                    <p>
                                        L'information est au coeur de nos partages au quotidien.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {(this.state.actus.info || []).map((data, index) => {
                                return (
                                    <div className="col-md-4">
                                        <div className="single-blog-card card border-0 shadow-sm white-bg">
                                            {data.fileType === "PICTURE" ?
                                                <img src={data.image} className="card-img-top position-relative"
                                                     alt="blog"/>
                                                : (<video width="100%" className="card-img-top position-relative"
                                                          height="100%" controls>
                                                    <source src={data.urlVideo} type="video/mp4"/>
                                                    Your browser does not support the video tag.
                                                </video>)}
                                            <span
                                                className="category position-absolute badge badge-pill badge-primary">{data.actusType}</span>
                                            <div className="card-body">
                                                <div className="post-meta mb-2">
                                                    <ul className="list-inline meta-list">
                                                        <li className="list-inline-item">{data.date}</li>
                                                    </ul>
                                                </div>
                                                description
                                                <h3 className="h5 card-title">
                                                    <a href="#">{data.title}</a>
                                                </h3>

                                                <p className="card-text">{data.description}</p>
                                                <a href="blogLeft" className="detail-link">
                                                    Lire plus
                                                    <span className="ti-arrow-right"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }

}

export default Actus;