import React, {useEffect, useState} from "react";
import {db} from "../../../../../firebase/firebase";
import {productArrivedConverter} from "../../../../../models/ArrivedProduct";

const NewArrivalProduct = () => {

    const [productArrived, setProductArrived] = useState([]);

    const fetchProductArrived = async () => {
        db.collection("ProductArriveds")
            .withConverter(productArrivedConverter)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    setProductArrived(arr => [...arr, data])
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
    }

    useEffect(async () => {
        await fetchProductArrived();
    }, [])

    return (
        <div>
            <section className="py-4">
                <div className="container">
                    <div className="d-flex align-items-center">
                        <h5 className="text-uppercase mb-0">Nouvel arrivage</h5>
                        <a href="https://www.facebook.com/J2CODE"
                           className="btn btn-light ms-auto rounded-0">Voir tous<i
                            className='bx bx-chevron-right'></i></a>
                    </div>
                    <hr/>
                </div>
            </section>
            <section className="py-4">
                {productArrived.map((data, i) => (
                    <div className="container" key={i}>
                        <div className="add-banner">
                            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-4">
                                <div className="col d-flex">
                                    <div className="card rounded-0 w-100">
                                        <img src={data.urlPicture} className="card-img-top" alt="..."/>
                                        <div
                                            className="position-absolute top-0 end-0 m-3 product-discount">
                                            <span className="">{data.rate}</span>
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">{data.productName}</h5>
                                            <p className="card-text">{data.productSummary}</p>
                                            <a href="/store/detail-product" className="btn btn-light btn-ecomm">
                                                Acheter maintenant
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </section>
        </div>

    );
}

export default NewArrivalProduct;