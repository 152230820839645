class ProductArrived {
    constructor(id, productName, productSummary, urlPicture, productId, rate) {
        this.id = id;
        this.productName = productName;
        this.productSummary = productSummary;
        this.urlPicture = urlPicture;
        this.productId = productId;
        this.rate = rate;
    }

    toString() {
        return this.id + ', ' + this.productName + ', ' + this.productSummary + ', ' + this.urlPicture + ', ' + this.productId + ", " + this.rate;
    }
}

//Firestore data converter
export const productArrivedConverter = {
    toFireStore: function (productArrived) {
        return {
            id: productArrived.id,
            productName: productArrived.productName,
            productSummary: productArrived.productSummary,
            urlPicture: productArrived.urlPicture,
            productId: productArrived.productId,
            rate: productArrived.rate
        }
    },
    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);
        return new ProductArrived(data.id, data.productName, data.productSummary, data.urlPicture, data.productId, data.rate);
    }
};