import React, {useEffect, useState} from "react";

const OurServices = () => {
    return (
        <div>
            <section className="py-4 bg-dark-1 border-bottom">
                <div className="container">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4 row-group d-flex justify-content-center">
                        <div className="col">
                            <div className="text-center">
                                <div className="font-50 text-white">
                                    <i className='bx bx-mobile'></i>
                                </div>
                                <h2 className="fs-5 text-uppercase mb-0">Conception Application Web - Mobile</h2>
                                <p className="text-capitalize">A partir de 200.000 XOF</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis nec
                                    vestibulum magna, et dapib.</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="text-center">
                                <div className="font-50 text-white">
                                    <i className='bx bx-credit-card'></i>
                                </div>
                                <h2 className="fs-5 text-uppercase mb-0">Conception 3D</h2>
                                <p className="text-capitalize">A partir de 500.000 XOF</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis nec
                                    vestibulum magna, et dapib.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default OurServices;