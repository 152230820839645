module.exports = {
    hero: {
        title: "Brainstorming for desired perfect Usability",
        description:
            "Our design projects are fresh and simple and will benefit your business greatly. Learn more about our work!",
        bgImage: "img/app-hero-bg.jpg"
    },
    promo: {
        title: "Why small business owners love AppCo?",
        description:
            "Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts",
        items: [
            {
                title: "Clean Design",
                description:
                    "Increase sales by showing true dynamics of your website",
                icon: "ti-vector text-white"
            },
            {
                title: "Secure Data",
                description:
                    "Build your online store’s trust using Social Proof & Urgency",
                icon: "ti-lock text-white"
            },
            {
                title: "Retina Ready",
                description:
                    "Realize importance of social proof in customer’s purchase decision",
                icon: "ti-eye text-white"
            }
        ]
    },
    aboutUs: {
        title: {
            bold: "Use your android or ios device",
            normal: ""
        },
        description:
            "Following reasons show advantages of adding AgencyCo to your lead pages, demos and checkouts",
        items: [
            {
                title: "Clean Design",
                description:
                    "Increase sales by showing true dynamics of your website",
                icon: "ti-vector text-white"
            },
            {
                title: "Secure Data",
                description:
                    "Build your online store’s trust using Social Proof & Urgency",
                icon: "ti-lock text-white"
            },
            {
                title: "Retina Ready",
                description:
                    "Realize importance of social proof in customer’s purchase decision",
                icon: "ti-eye text-white"
            }
        ]
    },
    price: {
        title: "Pricing Packages",
        description:
            "Uniquely maintain efficient expertise whereas cross functional deliverables. Continually leverage other's competitive mind share whereas enabled channels. ",
        packages: [
            {
                price: "$29",
                license: "Basic",
                isFeatured: false,
                features: [
                    "Push Notifications",
                    "Data Transfer",
                    "SQL Database",
                    "Search & SEO Analytics",
                    "24/7 Phone Support",
                    "2 months technical support",
                    "2+ profitable keyword"
                ]
            },
            {
                price: "$50",
                license: "Popular",
                isFeatured: true,
                features: [
                    "Push Notifications",
                    "Data Transfer",
                    "SQL Database",
                    "Search & SEO Analytics",
                    "24/7 Phone Support",
                    "6 months technical support",
                    "30+ profitable keyword"
                ]
            },
            {
                price: "$120",
                license: "Extended",
                isFeatured: false,
                features: [
                    "Push Notifications",
                    "Data Transfer",
                    "SQL Database",
                    "Search & SEO Analytics",
                    "24/7 Phone Support",
                    "1 year technical support",
                    "70+ profitable keyword"
                ]
            }
        ]
    },
    testimonial: {
        title: "What customer say about us",
        description:
            'Rapidiously morph transparent internal or "organic" sources whereas resource sucking e-business. Conveniently innovate compelling internal.',
        comments: [
            {
                name: "Christelle N’CHO ASSIROU",
                company: "Manager Général de ICTINA et Fondatrice de Femmes & Tics.",
                image: "https://res.cloudinary.com/j2code/image/upload/v1647866279/website/img/speaker/speaker-1_nhpcbh.png",
                comment:
                    "La gente féminine a sa part à jouer dans le développement de l’écosystème numérique en Côte d’Ivoire"
            },
            {
                name: "Sahourey Hervé KONAN",
                company: "Expert-Consultant, Coach Formateur TIC, Transformation Digital",
                image: "https://res.cloudinary.com/j2code/image/upload/v1647866279/website/img/speaker/speaker-3_ymwz4j.png",
                comment:
                    "Avec plus de 20 ans d'expérience dans la gestion de projet TIC en qualité de consultant sénior,\n" +
                    "                                        Sahourey Konan a pris part aux réalisations de nombreux projets et solutions\n" +
                    "                                        informatiques depuis la conception à l’implémentation de la refonte des systèmes\n" +
                    "                                        d’information et de gestion organisationnelle. Coach-formateur & promoteur de\n" +
                    "                                        projets start-up, M. Konan est diplômé d’un Exécutive MBA en Technologie de\n" +
                    "                                        l’information obtenu à Ecole de Science de Gestion de l’Université du Quebec à\n" +
                    "                                        Montréal(UQAM), certifié en Gestion de Projet et Gouvernance d’entreprise IT. A\n" +
                    "                                        présent, il intervient comme Expert-Conseil dans plusieurs centres d’incubation\n" +
                    "                                        de start-up d’organismes privés en Afrique."
            },
            {
                name: "Léger DJIBA",
                company: "Solution Architect & Technical Lead Consultant à la BAD",
                image: "https://res.cloudinary.com/j2code/image/upload/v1647866279/website/img/speaker/speaker-2_hgmhxh.png",
                comment:
                    "Léger DJIBA est un\n" +
                    "                                        consultant et formateur spécialisé dans les meilleures pratiques de\n" +
                    "                                        développement de logiciels utilisant la technologie Microsoft. Il est un MVP\n" +
                    "                                        Microsoft Visual Studio et Development Technologies, Devops et Xamarin Guru,\n" +
                    "                                        Polyglot (anglais, français, wolof, Diola), il vit actuellement à Dakar, au\n" +
                    "                                        Sénégal. Léger est titulaire d’un BTS et d’un DIC (Ingénieur de Conception en\n" +
                    "                                        Génie Logiciel). Il est Ingénieur Logiciel avec plus de 10 ans d’expérience en\n" +
                    "                                        Génie Logiciel et développement d’applications d’entreprise. Il possède une\n" +
                    "                                        vaste expérience dans divers secteurs: biométrie, commerce électronique et\n" +
                    "                                        développement de technologies telles que: Microsoft. NET, JAVA, architecture\n" +
                    "                                        N-tier, UML, AGILE (XP, Scrum, Lean), Devops, technologies open source, Xamarin,\n" +
                    "                                        programmation Arduino. Il a commencé à développer en ANDROID 2009 et Windows\n" +
                    "                                        Phone en 2011, Xamarin."
            },
            {
                name: "Souleymane SANOGO",
                company: "Expert du langage de programmation Java",
                image: "https://res.cloudinary.com/j2code/image/upload/v1647866280/website/img/speaker/speaker-4_lzavri.png",
                comment:
                    "La\n" +
                    "                                        programmation, les réseaux, l’électronique, les télécommunications et même la\n" +
                    "                                        sécurité informatique n’ont plus de secret. "
            }
        ]
    },
    contact: {
        title: "Nous contactez",
        description:
            "Il est très facile d'entrer en contact avec nous. Utilisez simplement le formulaire de\n" +
            "                            contact ou rendez-nous visite pour un café au bureau.",

        office: "Siege",
        address: "Riviera 2, Cocody, Côte d'Ivoire",
        phone: "+225 0758661307",
        email: "info@j2code.com"
    },
    trustedCompany: [
        "img/client-1-color.png",
        "img/client-6-color.png",
        "img/client-3-color.png",
        "img/client-4-color.png",
        "img/client-5-color.png"
    ],
    trustedCompanyGray: [
        "img/client-1-gray.png",
        "img/client-2-gray.png",
        "img/client-3-gray.png",
        "img/client-4-gray.png",
        "img/client-5-gray.png"
    ],
    trustedCompanyAboutUs: {
        title: "Nos partenaires",
        description: "Transformer rapidement les sources internes ou \"organiques\" transparentes en sources\n" +
            "                            aspirant les ressources.",
        companies: [
            "https://res.cloudinary.com/j2code/image/upload/v1647866285/website/img/partenaires/1_e1q14t.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866287/website/img/partenaires/2_qp0huf.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866288/website/img/partenaires/3_pt8rta.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866286/website/img/partenaires/4_tfp2y9.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866287/website/img/partenaires/5_b8lsst.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866286/website/img/partenaires/6_saxuxi.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866286/website/img/partenaires/7_qq5xmz.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866286/website/img/partenaires/8_qaemdn.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866287/website/img/partenaires/9_udi01e.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866284/website/img/partenaires/10_qt4fv4.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866285/website/img/partenaires/11_z5cfsl.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866287/website/img/partenaires/12_rxxuc7.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866288/website/img/partenaires/13_hipqgc.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866286/website/img/partenaires/14_rzmryp.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866286/website/img/partenaires/15_aangun.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866285/website/img/partenaires/16_dkkadq.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866286/website/img/partenaires/17_szd4kz.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866286/website/img/partenaires/18_olgmhh.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866287/website/img/partenaires/19_ni6izf.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866285/website/img/partenaires/20_yioab2.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866286/website/img/partenaires/21_iwfnm7.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866286/website/img/partenaires/22_xvndlb.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866287/website/img/partenaires/23_objptx.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866287/website/img/partenaires/24_l3qpyx.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866288/website/img/partenaires/25_k1wlar.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866285/website/img/partenaires/26_mm0lhf.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866287/website/img/partenaires/28_oxldnd.jpg",
            "https://res.cloudinary.com/j2code/image/upload/v1647866288/website/img/partenaires/29_cjo22f.jpg",
        ]
    },
    teamMember: {
        members: [
            {
                id: new Date().getMilliseconds().toString(),
                name: "Noely",
                designation: "FOUNDER",
                image: "https://res.cloudinary.com/j2code/image/upload/v1693230419/website/img/team/noely.png",
                twitter: "https://twitter.com/noelynguessan",
                linkedin: "https://www.linkedin.com/in/noelynguessan"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Emmanuella",
                designation: "DAF",
                image: "https://res.cloudinary.com/j2code/image/upload/v1693230789/website/img/team/EmmaK.png",
                twitter: "https://twitter.com/EmmanuellaKpata",
                linkedin: "https://www.linkedin.com/in/emmanuella-kpata-8854051a5"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Cannel ",
                designation: "CTO",
                image: "https://res.cloudinary.com/j2code/image/upload/v1693224115/website/img/team/cannel.jpg",
                twitter: "https://twitter.com/CannelSeka",
                linkedin: "https://www.linkedin.com/in/seka-cannel-ulrich-evrard-7b3006100"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Christelle ",
                designation: "Project Manager",
                image: "https://res.cloudinary.com/j2code/image/upload/v1693224114/website/img/team/christelle.jpg",
                twitter: "#",
                linkedin: "https://www.linkedin.com/in/christelle-n-guessan-b0177760/"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Francine",
                designation: "Lead CMS",
                image: "https://res.cloudinary.com/j2code/image/upload/v1665457714/zoomsurpassion/speaker/Nouveau_projet_33_llnv5m.png",
                twitter: "https://twitter.com/oyeniyidev",
                linkedin: "https://www.linkedin.com/in/sunoyeniyi"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Sunday",
                designation: "Lead Frontend",
                image: "https://res.cloudinary.com/j2code/image/upload/v1693326432/website/img/team/sanday.png",
                twitter: "https://twitter.com/oyeniyidev",
                linkedin: "https://www.linkedin.com/in/sunoyeniyi"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Nana",
                designation: "Lead QA",
                image: "https://res.cloudinary.com/j2code/image/upload/v1665455236/zoomsurpassion/speaker/Nouveau_projet_29_wdqwdl.png",
                twitter: "https://twitter.com/oyeniyidev",
                linkedin: "https://www.linkedin.com/in/sunoyeniyi"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Marius",
                designation: "Lead Design",
                image: "https://res.cloudinary.com/j2code/image/upload/v1665453065/zoomsurpassion/speaker/Nouveau_projet_22_y27jax.png",
                twitter: "https://twitter.com/oyeniyidev",
                linkedin: "https://www.linkedin.com/in/sunoyeniyi"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Patrick",
                designation: "Lead Com",
                image: "https://res.cloudinary.com/j2code/image/upload/v1693229786/website/img/team/patrick.png",
                twitter: "https://twitter.com/oyeniyidev",
                linkedin: "https://www.linkedin.com/in/sunoyeniyi"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Touma",
                designation: "Lead FinTech",
                image: "https://res.cloudinary.com/j2code/image/upload/v1693229785/website/img/team/touma.png",
                twitter: "https://twitter.com/oyeniyidev",
                linkedin: "https://www.linkedin.com/in/sunoyeniyi"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Blender",
                designation: "Lead 3D",
                image: "https://res.cloudinary.com/j2code/image/upload/v1693229784/website/img/team/blender.png",
                twitter: "https://twitter.com/oyeniyidev",
                linkedin: "https://www.linkedin.com/in/sunoyeniyi"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Jean Christ",
                designation: "Lawyer",
                image: "https://res.cloudinary.com/j2code/image/upload/v1693235264/website/img/team/JC.png",
                twitter: "https://twitter.com/oyeniyidev",
                linkedin: "https://www.linkedin.com/in/sunoyeniyi"
            }
        ]
    },
    blogs: {
        articles: [
            {
                id: new Date().getMilliseconds().toString(),
                title: "Education",
                date: "Fev 21, 2022",
                image: "https://res.cloudinary.com/j2code/image/upload/v1647325408/website/actu1_u8aacj.png",
                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam nec scelerisque ex. Proin suscipit commodo mauris. Donec sed molestie erat, eget vulputate eros. Suspendisse ligula erat, sodales sed tellus ut, imperdiet fringilla erat. Nulla lacinia neque quam, et elementum odio faucibus sed. Cras nec malesuada sapien. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Integer porta risus id purus varius, et commodo tellus gravida. Phasellus a ultricies nunc. Aenean convallis orci quis orci porttitor, sed tincidunt leo tincidunt. Sed sed ipsum euismod augue hendrerit mattis vitae sed diam. Donec venenatis nunc ut dictum malesuada. Cras et leo elit. Cras id posuere justo. Nulla facilisi."
            }
        ]
    },
    teamAllMembers: {
        members: [
            {
                id: new Date().getMilliseconds().toString(),
                name: "Noely",
                designation: "FOUNDER",
                image: "https://res.cloudinary.com/j2code/image/upload/v1693230419/website/img/team/noely.png",
                twitter: "https://twitter.com/noelynguessan",
                linkedin: "https://www.linkedin.com/in/noelynguessan"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Emmanuella",
                designation: "DAF",
                image: "https://res.cloudinary.com/j2code/image/upload/v1693230789/website/img/team/EmmaK.png",
                twitter: "https://twitter.com/EmmanuellaKpata",
                linkedin: "https://www.linkedin.com/in/emmanuella-kpata-8854051a5"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Cannel ",
                designation: "CTO",
                image: "https://res.cloudinary.com/j2code/image/upload/v1693224115/website/img/team/cannel.jpg",
                twitter: "https://twitter.com/CannelSeka",
                linkedin: "https://www.linkedin.com/in/seka-cannel-ulrich-evrard-7b3006100"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Christelle ",
                designation: "Project Manager",
                image: "https://res.cloudinary.com/j2code/image/upload/v1693224114/website/img/team/christelle.jpg",
                twitter: "https://twitter.com/oyeniyidev",
                linkedin: "https://www.linkedin.com/in/sunoyeniyi"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Francine",
                designation: "Lead CMS",
                image: "https://res.cloudinary.com/j2code/image/upload/v1665457714/zoomsurpassion/speaker/Nouveau_projet_33_llnv5m.png",
                twitter: "https://twitter.com/oyeniyidev",
                linkedin: "https://www.linkedin.com/in/sunoyeniyi"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Sunday",
                designation: "Lead Frontend",
                image: "https://res.cloudinary.com/j2code/image/upload/v1693326432/website/img/team/sanday.png",
                twitter: "https://twitter.com/oyeniyidev",
                linkedin: "https://www.linkedin.com/in/sunoyeniyi"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Nana",
                designation: "Lead QA",
                image: "https://res.cloudinary.com/j2code/image/upload/v1665455236/zoomsurpassion/speaker/Nouveau_projet_29_wdqwdl.png",
                twitter: "https://twitter.com/oyeniyidev",
                linkedin: "https://www.linkedin.com/in/sunoyeniyi"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Marius",
                designation: "Lead Design",
                image: "https://res.cloudinary.com/j2code/image/upload/v1665453065/zoomsurpassion/speaker/Nouveau_projet_22_y27jax.png",
                twitter: "https://twitter.com/oyeniyidev",
                linkedin: "https://www.linkedin.com/in/sunoyeniyi"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Patrick",
                designation: "Lead Com",
                image: "https://res.cloudinary.com/j2code/image/upload/v1693229786/website/img/team/patrick.png",
                twitter: "https://twitter.com/oyeniyidev",
                linkedin: "https://www.linkedin.com/in/sunoyeniyi"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Touma",
                designation: "Lead FinTech",
                image: "https://res.cloudinary.com/j2code/image/upload/v1693229785/website/img/team/touma.png",
                twitter: "https://twitter.com/oyeniyidev",
                linkedin: "https://www.linkedin.com/in/sunoyeniyi"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Blender",
                designation: "Lead 3D",
                image: "https://res.cloudinary.com/j2code/image/upload/v1693229784/website/img/team/blender.png",
                twitter: "https://twitter.com/oyeniyidev",
                linkedin: "https://www.linkedin.com/in/sunoyeniyi"
            },
            {
                id: new Date().getMilliseconds().toString(),
                name: "Jean Christ",
                designation: "Lawyer",
                image: "https://res.cloudinary.com/j2code/image/upload/v1693235264/website/img/team/JC.png",
                twitter: "https://twitter.com/oyeniyidev",
                linkedin: "https://www.linkedin.com/in/sunoyeniyi"
            }
        ]
    },
    video: {
        link: "https://www.youtube.com/watch?v=9No-FiEInLA"
    },
    actus: {
        info: [
            {
                id: new Date().getMilliseconds().toString(),
                actusType: "Education",
                image: "https://res.cloudinary.com/j2code/image/upload/v1647325408/website/actu1_u8aacj.png",
                urlVideo: "",
                date: "Fev 21, 2022",
                title: "Formation en infographie 3D",
                fileType: "PICTURE",
                description: "Vous avez 𝐞𝐧𝐭𝐫𝐞 𝟏𝟖 𝐞𝐭 𝟑𝟓 𝐚𝐧𝐬, Postulez au programme #𝐃𝐂𝐋𝐈𝐂 de l'#OIF!"
            },
            {
                id: new Date().getMilliseconds().toString(),
                actusType: "Education",
                image: "",
                urlVideo: "https://res.cloudinary.com/j2code/video/upload/v1647325786/website/Dclic_lrjyqy.mp4",
                date: "Jan 27, 2022",
                title: "Formation D-CLIC",
                fileType: "MOVIE",
                description: "L’Organisation internationale de la Francophonie (OIF) a lancé la phase pilote."
            },
            {
                id: new Date().getMilliseconds().toString(),
                actusType: "Education",
                image: "https://res.cloudinary.com/j2code/image/upload/v1647325407/website/actu3_xzwuli.png",
                urlVideo: "",
                date: "Jan 19, 2022",
                title: "Formation D-CLIC",
                fileType: "PICTURE",
                description: "𝐏𝐫𝐨𝐠𝐫𝐚𝐦𝐦𝐞 𝐃-𝐂𝐋𝐈𝐂, 𝐅𝐨𝐫𝐦𝐞𝐳-𝐯𝐨𝐮𝐬 𝐚𝐮𝐱 𝐦𝐞́𝐭𝐢𝐞𝐫𝐬 𝐝𝐮 𝐍𝐮𝐦𝐞́𝐫𝐢𝐪𝐮𝐞 𝐚𝐯𝐞𝐜 𝐥'𝐎𝐈𝐅"
            }
        ]
    }
};
