import NavbarStore from "../../../home/presentation/components/NavbarStore";
import FooterStore from "../../../home/presentation/components/FooterStore";
import React from "react";
import {useEffect, useState} from "react";
import {db} from "../../../../../firebase/firebase";
import {productCategoryConverter} from "../../../../../models/ProductCategory";
import ItemProductByCategory from "../components/ItemProductByCategory";

const CategoryAll = () => {

    const [category, setCategory] = useState([]);

    const fetchCategory = async () => {
        db.collection("ProductCategories")
            .withConverter(productCategoryConverter)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const category = doc.data();
                    category.id = doc.id;
                    setCategory(arr => [...arr, category])
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
    }

    useEffect(async () => {
        await fetchCategory();
    }, [])

    return (
        <React.Fragment>
            <div className="bg-theme bg-theme2" style={{color: 'white'}}>
                <div className="wrapper">
                    <NavbarStore/>
                    <div className="page-wrapper">
                        <section className="py-3 border-bottom d-none d-md-flex">
                            <div className="container">
                                <div className="page-breadcrumb d-flex align-items-center">
                                    <h3 className="breadcrumb-title pe-3">Nos categories</h3>
                                    <div className="ms-auto">
                                        <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb mb-0 p-0">
                                                <li className="breadcrumb-item"><a href="/store"><i
                                                    className="bx bx-home-alt"></i> Accueil</a>
                                                </li>
                                                <li className="breadcrumb-item"><a href="javascript:;">Nos
                                                    categories</a>
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="py-4">
                            <div className="container">
                                <div className="product-categories">
                                    <div className="row row-cols-1 row-cols-lg-4">
                                        {category.map((item, i) => (
                                            <div className="col" key={i}>
                                                <div className="card rounded-0 product-card">
                                                    <a href="javascript:;">
                                                        <img height={260} width={150} src={item.urlPicture}
                                                             className="card-img-top border-bottom bg-dark-1"
                                                             alt="..."/>
                                                    </a>
                                                    <div className="list-group list-group-flush">
                                                        <a href="javascript:;"
                                                           className="list-group-item bg-transparent">
                                                            <h6 className="mb-0 text-uppercase">{item.name}</h6>
                                                        </a>
                                                        <ItemProductByCategory data={item.id}/>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <FooterStore/>
                    <a href="https://www.facebook.com/J2CODE" className="back-to-top"><i
                        className='bx bxs-up-arrow-alt'></i></a>
                </div>
                <a href="https://www.facebook.com/J2CODE" className="back-to-top"><i
                    className='bx bxs-up-arrow-alt'></i></a>
            </div>
        </React.Fragment>
    );
}

export default CategoryAll;