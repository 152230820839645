import React from "react";
import FooterStore from "../components/FooterStore";
import CategoryHomeStore from "../components/CategoryHomeStore";
import FeaturedProducts from "../components/FeaturedProducts";
import NewArrivalProduct from "../components/NewArrivalProduct";
import OurServices from "../components/OurServices";
import HomeSlider from "../components/HomeSlider";
import NavbarStore from "../components/NavbarStore";

class Store extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.setState({})
    }

    render() {
        return (
            <React.Fragment>
                <div className="bg-theme bg-theme2" style={{color: 'white'}}>
                    <div className="wrapper">
                        <NavbarStore/>
                        <HomeSlider/>
                        <div className="page-wrapper">
                            <div className="page-content">
                                <section className="py-3 border-top border-bottom">
                                    <div className="container">
                                        <div className="row row-cols-1 row-cols-lg-3 row-group align-items-center">
                                            <div className="col p-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="fs-1 text-white">
                                                        <i className='bx bx-taxi'></i>
                                                    </div>
                                                    <div className="info-box-content ps-3">
                                                        <h6 className="mb-0">EXPÉDITION ET RETOUR GRATUITS</h6>
                                                        <p className="mb-0">Livraison gratuite pour toute commande
                                                            supérieure à 25.000 XOF</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col p-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="fs-1 text-white">
                                                        <i className='bx bx-dollar-circle'></i>
                                                    </div>
                                                    <div className="info-box-content ps-3">
                                                        <h6 className="mb-0">GARANTIE DE REMBOURSEMENT</h6>
                                                        <p className="mb-0">Garantie de remboursement à 100%</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col p-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="fs-1 text-white">
                                                        <i className='bx bx-support'></i>
                                                    </div>
                                                    <div className="info-box-content ps-3">
                                                        <h6 className="mb-0">SUPPORT EN LIGNE 24/7</h6>
                                                        <p className="mb-0">Une assistance impressionnante pour les
                                                            jours 24/7</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <CategoryHomeStore/>
                                <FeaturedProducts/>
                                <NewArrivalProduct/>
                                <OurServices/>
                            </div>
                        </div>
                        <FooterStore/>
                        <a href="https://www.facebook.com/J2CODE" className="back-to-top"><i
                            className='bx bxs-up-arrow-alt'></i></a>
                    </div>
                    <a href="https://www.facebook.com/J2CODE" className="back-to-top"><i
                        className='bx bxs-up-arrow-alt'></i></a>
                </div>
            </React.Fragment>
        );
    }
}

export default Store;