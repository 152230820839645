import React from "react";

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
    }

    render() {
        return (
            <React.Fragment>
                <section id="about" className="about-us ptb-100 gray-light-bg">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-md-6">

                                <div className="about-content-left section-heading">
                                    <span className="text-uppercase color-secondary sub-title">LA VISION : </span>
                                    <h5><br/>Investir dans le rêve numérique des jeunes Africains.</h5>

                                    <span className="text-uppercase color-secondary sub-title">LA MISSION : </span>
                                    <h5><br/>La mission de notre communauté technologique est de catalyser l'innovation,
                                        l'entrepreneuriat et le développement durable à travers la technologie. 
                                        Nous nous engageons à rassembler des esprits créatifs, 
                                        des experts technologiques et des passionnés du continent pour créer un écosystème dynamique 
                                        où l'innovation prospère, les compétences technologiques s'épanouissent et les défis locaux 
                                        trouvent des solutions numériques. </h5>
                                    <span className="text-uppercase color-secondary sub-title">LE DOUBLE OBJECTIF : </span>
                                    <div className="single-feature mb-4">
                                        <div className="d-flex align-items-center mb-2">
                                            <span className="ti-book rounded mr-3 icon icon-color-6"/>
                                            <h5 className="mb-0"> Education</h5>
                                        </div>
                                        <p>Renforcer les compétences technologiques par une éducation populaire.</p>
                                    </div>
                                    <div className="single-feature mb-4">
                                        <div className="d-flex align-items-center mb-2">
                                            <span className="ti-bag rounded mr-3 icon icon-color-5"/>
                                            <h5 className="mb-0"> Employabilité</h5>
                                        </div>
                                        <p>Accroître la compétitivité des jeunes Africains selon le standard international.</p>
                                    </div>

                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="about-content-right">
                                    <img
                                        src="https://res.cloudinary.com/j2code/image/upload/v1647866331/website/img/other-background_b8bsut.png"
                                        alt="about us" className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default About;
