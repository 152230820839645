import firebase from "firebase";

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyDcJlHVkrOgMi-uGIE-JbMabPR0viEunt8",
    authDomain: "j2code.firebaseapp.com",
    databaseURL: "https://j2code.firebaseio.com",
    projectId: "j2code",
    storageBucket: "j2code.appspot.com",
    messagingSenderId: "47651590991",
    appId: "1:47651590991:web:8b80338e263180170a6b3a",
    measurementId: "G-8RBZHX6ZGZ"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export {
    db
};
