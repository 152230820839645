class ProductFeatured {
    constructor(id, categoryName, urlPicture, price, productName, productId, oldPrice) {
        this.id = id;
        this.categoryName = categoryName;
        this.urlPicture = urlPicture;
        this.price = price;
        this.productName = productName;
        this.productId = productId;
        this.oldPrice = oldPrice;
    }

    toString() {
        return this.id + ', ' + this.categoryName + ', ' + this.urlPicture + ', ' + this.price + ', ' + this.productName + ', ' + this.productId + ', ' + this.oldPrice;
    }
}

//Firestore data converter
export const productFeaturedConverter = {
    toFireStore: function (productFeatured) {
        return {
            id: productFeatured.id,
            categoryName: productFeatured.categoryName,
            urlPicture: productFeatured.urlPicture,
            price: productFeatured.price,
            productName: productFeatured.productName,
            productId: productFeatured.productId,
            oldPrice: productFeatured.oldPrice
        }
    },
    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);
        return new ProductFeatured(data.id, data.categoryName, data.urlPicture, data.price, data.productName, data.productId, data.oldPrice);
    }
};