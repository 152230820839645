import React from 'react';

const Breadcrumb = ({point, title}) => {
    return (
        <section className="py-3 border-bottom d-none d-md-flex">
        <div className="container">
            <div className="page-breadcrumb d-flex align-items-center">
                <h3 className="breadcrumb-title pe-3">{title}</h3>
                <div className="ms-auto">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item"><a href="javascript:;"><i className="bx bx-home-alt"></i> Accueil</a>
                            </li>
                            <li className="breadcrumb-item"><a href="javascript:;">Compte</a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">{point}</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </section>
    );
};

export default Breadcrumb;