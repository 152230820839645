import React, {useState} from "react";
import firebase from "firebase";
import {NavLink} from "react-router-dom";

const logOut = async () => {
    try {
        await firebase.auth().signOut().then(() => {
            // Sign-out successful.
        });
    } catch {
        alert("For some reasons we can't deconnect, please check your internet connexion and retry.")
    }
}

const NavbarStore = () => {

    const [userIsLogin, setUserIsLogin] = useState(false)

    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            const uid = user.uid;
            setUserIsLogin(true)
        } else {
            setUserIsLogin(false)
        }
    });

    return (
        <div className="header-wrapper bg-dark-1">
            <div className="top-menu border-bottom">
                <div className="container">
                    <nav className="navbar navbar-expand">
                        <div className="logo d-none d-lg-flex">
                            <a href="/">
                                <img
                                    src="https://res.cloudinary.com/j2code/image/upload/v1647866303/website/img/logo-j2code_povnzd.png"
                                    className="logo-icon" alt=""/>
                            </a>
                        </div>

                        <ul className="navbar-nav ms-auto d-none d-lg-flex">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle"
                                   href=""
                                   data-bs-toggle="dropdown">
                                    Événements
                                </a>
                                <ul className="dropdown-menu dropdown-menu-lg-end">
                                    <li>
                                        <a className="dropdown-item" href="/events-j2c-2018">Edition
                                            2018</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/store">
                                    Boutique
                                </a>
                            </li>
                            <li className="nav-item"><a className="nav-link" href="team">Equipe</a>
                            </li>

                            {userIsLogin ? (<li className="nav-item">
                                <button onClick={logOut} className="btn btn-sm btn-danger">Deconnexion</button>
                            </li>) : <span/>}

                        </ul>
                        <ul className="navbar-nav social-link ms-lg-2 ms-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="https://www.facebook.com/J2CODE">
                                    <i className='bx bxl-facebook'/>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://twitter.com/journeeducode">
                                    <i className='bx bxl-twitter'/>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://www.linkedin.com/company/j2code">
                                    <i className='bx bxl-linkedin'/>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div className="header-content pb-3 pb-md-0">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col-md-auto">
                            <div className="d-flex align-items-center">
                                <div className="mobile-toggle-menu d-lg-none px-lg-2"
                                     data-trigger="#navbar_main"><i className='bx bx-menu'></i>
                                </div>
                                <div className="logo d-none d-lg-flex">
                                    <a href="https://www.facebook.com/J2CODE">
                                        <img src="src/components/Store/home/presentation/pages/Store"
                                             className="logo-icon" alt=""/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col col-md-auto order-3 d-none d-xl-flex align-items-center">
                            <div className="fs-1 text-white"><i className='bx bx-headphone'></i>
                            </div>
                            <div className="ms-2">
                                <p className="mb-0 font-13">APPELEZ NOUS MAINTENANT</p>
                                <h5 className="mb-0">+225 0758661307</h5>
                            </div>
                        </div>
                        <div className="col col-md-auto order-2 order-md-4">
                            <div className="top-cart-icons">
                                <nav className="navbar navbar-expand">
                                    <ul className="navbar-nav ms-auto">
                                        {!userIsLogin ? (<li className="nav-item">
                                            <a href="/signup" className="nav-link cart-link">
                                                <i className='bx bx-user'/>
                                            </a>
                                        </li>) : <span/>}
                                        {userIsLogin ? (<li className="nav-item dropdown dropdown-large">
                                            <a href="https://www.facebook.com/J2CODE"
                                               className="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative cart-link"
                                               data-bs-toggle="dropdown">
                                                <span className="alert-count">8</span>
                                                <i className='bx bx-shopping-bag'/>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-end">
                                                <a href="https://www.facebook.com/J2CODE">
                                                    <div className="cart-header">
                                                        <p className="cart-header-title mb-0">8 ITEMS</p>
                                                        <p className="cart-header-clear ms-auto mb-0">VIEW CART</p>
                                                    </div>
                                                </a>
                                                <div className="cart-list">
                                                    <a className="dropdown-item"
                                                       href="https://www.facebook.com/J2CODE">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-grow-1">
                                                                <h6 className="cart-product-title">Men
                                                                    White T-Shirt</h6>
                                                                <p className="cart-product-price">1 X
                                                                    $29.00</p>
                                                            </div>
                                                            <div className="position-relative">
                                                                <div
                                                                    className="cart-product-cancel position-absolute">
                                                                    <i className='bx bx-x'></i>
                                                                </div>
                                                                <div className="cart-product">
                                                                    <img
                                                                        src="src/components/Store/home/presentation/pages/Store"
                                                                        className=""
                                                                        alt="product image"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a className="dropdown-item"
                                                       href="https://www.facebook.com/J2CODE">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-grow-1">
                                                                <h6 className="cart-product-title">Puma
                                                                    Sports Shoes</h6>
                                                                <p className="cart-product-price">1 X
                                                                    $29.00</p>
                                                            </div>
                                                            <div className="position-relative">
                                                                <div
                                                                    className="cart-product-cancel position-absolute">
                                                                    <i className='bx bx-x'></i>
                                                                </div>
                                                                <div className="cart-product">
                                                                    <img
                                                                        src="src/components/Store/home/presentation/pages/Store"
                                                                        className=""
                                                                        alt="product image"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a className="dropdown-item"
                                                       href="https://www.facebook.com/J2CODE">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-grow-1">
                                                                <h6 className="cart-product-title">Women
                                                                    Red Sneakers</h6>
                                                                <p className="cart-product-price">1 X
                                                                    $29.00</p>
                                                            </div>
                                                            <div className="position-relative">
                                                                <div
                                                                    className="cart-product-cancel position-absolute">
                                                                    <i className='bx bx-x'></i>
                                                                </div>
                                                                <div className="cart-product">
                                                                    <img
                                                                        src="src/components/Store/home/presentation/pages/Store"
                                                                        className=""
                                                                        alt="product image"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a className="dropdown-item"
                                                       href="https://www.facebook.com/J2CODE">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-grow-1">
                                                                <h6 className="cart-product-title">Black
                                                                    Headphone</h6>
                                                                <p className="cart-product-price">1 X
                                                                    $29.00</p>
                                                            </div>
                                                            <div className="position-relative">
                                                                <div
                                                                    className="cart-product-cancel position-absolute">
                                                                    <i className='bx bx-x'></i>
                                                                </div>
                                                                <div className="cart-product">
                                                                    <img
                                                                        src="src/components/Store/home/presentation/pages/Store"
                                                                        className=""
                                                                        alt="product image"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a className="dropdown-item"
                                                       href="https://www.facebook.com/J2CODE">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-grow-1">
                                                                <h6 className="cart-product-title">Blue
                                                                    Girl Shoes</h6>
                                                                <p className="cart-product-price">1 X
                                                                    $29.00</p>
                                                            </div>
                                                            <div className="position-relative">
                                                                <div
                                                                    className="cart-product-cancel position-absolute">
                                                                    <i className='bx bx-x'></i>
                                                                </div>
                                                                <div className="cart-product">
                                                                    <img
                                                                        src="src/components/Store/home/presentation/pages/Store"
                                                                        className=""
                                                                        alt="product image"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a className="dropdown-item"
                                                       href="https://www.facebook.com/J2CODE">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-grow-1">
                                                                <h6 className="cart-product-title">Men
                                                                    Leather Belt</h6>
                                                                <p className="cart-product-price">1 X
                                                                    $29.00</p>
                                                            </div>
                                                            <div className="position-relative">
                                                                <div
                                                                    className="cart-product-cancel position-absolute">
                                                                    <i className='bx bx-x'></i>
                                                                </div>
                                                                <div className="cart-product">
                                                                    <img
                                                                        src="src/components/Store/home/presentation/pages/Store"
                                                                        className=""
                                                                        alt="product image"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a className="dropdown-item"
                                                       href="https://www.facebook.com/J2CODE">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-grow-1">
                                                                <h6 className="cart-product-title">Men
                                                                    Yellow T-Shirt</h6>
                                                                <p className="cart-product-price">1 X
                                                                    $29.00</p>
                                                            </div>
                                                            <div className="position-relative">
                                                                <div
                                                                    className="cart-product-cancel position-absolute">
                                                                    <i className='bx bx-x'></i>
                                                                </div>
                                                                <div className="cart-product">
                                                                    <img
                                                                        src="src/components/Store/home/presentation/pages/Store"
                                                                        className=""
                                                                        alt="product image"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                    <a className="dropdown-item"
                                                       href="https://www.facebook.com/J2CODE">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-grow-1">
                                                                <h6 className="cart-product-title">Pool
                                                                    Charir</h6>
                                                                <p className="cart-product-price">1 X
                                                                    $29.00</p>
                                                            </div>
                                                            <div className="position-relative">
                                                                <div
                                                                    className="cart-product-cancel position-absolute">
                                                                    <i className='bx bx-x'></i>
                                                                </div>
                                                                <div className="cart-product">
                                                                    <img
                                                                        src="src/components/Store/home/presentation/pages/Store"
                                                                        className=""
                                                                        alt="product image"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <a href="https://www.facebook.com/J2CODE">
                                                    <div
                                                        className="text-center cart-footer d-flex align-items-center">
                                                        <h5 className="mb-0">TOTAL</h5>
                                                        <h5 className="mb-0 ms-auto">$189.00</h5>
                                                    </div>
                                                </a>
                                                <div className="d-grid p-3 border-top"><a
                                                    href="https://www.facebook.com/J2CODE"
                                                    className="btn btn-light btn-ecomm">CHECKOUT</a>
                                                </div>
                                            </div>
                                        </li>) : <span/>}

                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="primary-menu border-top">
                <div className="container">
                    <nav id="navbar_main" className="mobile-offcanvas navbar navbar-expand-lg">
                        <div className="offcanvas-header">
                            <button className="btn-close float-end"></button>
                            <h5 className="py-2 text-white">Navigation</h5>
                        </div>
                        <ul className="navbar-nav">
                            <li className="nav-item active"><a className="nav-link"
                                                               href="/store">Accueil </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/store/all-products">Notre magasin</a>
                            </li>
                            {userIsLogin?(<li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle dropdown-toggle-nocaret"
                                   href="https://www.facebook.com/J2CODE" data-bs-toggle="dropdown">
                                    Mon compte
                                    <i className='bx bx-chevron-down'></i></a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item"
                                           href="https://www.facebook.com/J2CODE">Tableau de bord</a>
                                    </li>
                                    <li>
                                        <NavLink to="/account/orders" className="dropdown-item">Commandes</NavLink>
                                    </li>
                                    <li><a className="dropdown-item"
                                           href="https://www.facebook.com/J2CODE">Methodes de paiements</a>
                                    </li>
                                    <li>
                                        <NavLink to="/account/detail-account" className="dropdown-item">Detail
                                            compte</NavLink>
                                    </li>
                                </ul>
                            </li>):<span/>}
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default NavbarStore;