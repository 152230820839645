import React, {useRef} from "react";
import firebase from "firebase";
import {useHistory} from 'react-router-dom';
import NavbarStore from "../Store/home/presentation/components/NavbarStore";
import FooterStore from "../Store/home/presentation/components/FooterStore";



export default function ForgotPass() {
	const history = useHistory();
	const forgotpass = (email) => firebase.auth().sendPasswordResetEmail(email)
		.then(() => {
		})
		.catch((error) => {
			var errorCode = error.code;
			var errorMessage = error.message;
		});
	const inputs = useRef([]);
	const addInputs = el => {
		if(el && !inputs.current.includes(el)){
			inputs.current.push(el)
		}
	}
    const formRef = useRef();
	const handleForm = async (e) => {
		e.preventDefault()
		try {
			const mail = await forgotpass(
				inputs.current[0].value
			);
            alert("Email de renitialisation envoyé.");
            history.push("/resetpass");
		} catch {
			alert("Erreur");
		}
	};
	return (
		<>
			<React.Fragment>
				<div className="bg-theme bg-theme2" style={{color: 'white'}}>
					<div className="wrapper">
						<NavbarStore/>
						<section className="py-2">
									<div className="container">
										<div
											className="py-2 d-flex align-items-center justify-content-center">
											<div className="card forgot-box">
												<div className="card-body">
													<div className="p-4 rounded  border">
														<h4 className="mt-2 font-weight-bold">Forgot Password?</h4>
														<p className="">Enter your registered email ID to reset the
															password</p>
														<form ref={formRef} onSubmit={handleForm} className="row g-3">
														<div className="my-4">
															<label className="form-label">Email id</label>
															<input ref={addInputs} type="email" className="form-control form-control-lg"
																   placeholder="example@user.com"/>
														</div>
														<div className="d-grid gap-2">
																<button type="submit"
																   className="btn btn-light btn-lg">Send</button>
															   <a href="/signin"
															   className="btn btn-light btn-lg"><i
																className='bx bx-arrow-back me-1'></i>Back to Login</a>
														</div>
														</form>
													</div>
												</div>
											</div>
										</div>
									</div>
								</section>
						<FooterStore/>
						<a href="javaScript:;" className="back-to-top"><i className='bx bxs-up-arrow-alt'></i></a>
					</div>
					<a href="https://www.facebook.com/J2CODE" className="back-to-top">
						<i className='bx bxs-up-arrow-alt'></i>
					</a>
				</div>
			</React.Fragment>
		 </>
		);
	}
