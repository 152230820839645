import React, {useEffect, useState} from "react";
import {db} from "../../../../../firebase/firebase";
import {productHomeSliderConverter} from "../../../../../models/ProductHomeSlider";

const HomeSlider = () => {

    const [productHomeSlider, setProductHomeSlider] = useState([]);

    const fetchProductHomeSlider = async () => {
        db.collection("ProductHomeSliders")
            .withConverter(productHomeSliderConverter)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    setProductHomeSlider(arr => [...arr, data])
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
    }

    useEffect(async () => {
        await fetchProductHomeSlider();
    }, [])


    return (
        <section className="slider-section">
            <div className="first-slider">
                <div id="carouselExampleDark" className="carousel slide" data-bs-ride="carousel">
                    <ol className="carousel-indicators">
                        {productHomeSlider.map((data, i) => (
                            <li data-bs-target="#carouselExampleDark" data-bs-slide-to={i}
                                className={i === 0 ? "active" : ""}></li>
                        ))}
                    </ol>
                    <div className="carousel-inner">
                        {productHomeSlider.map((data, i) => (
                            <div className={i === 0 ? "carousel-item active" : "carousel-item"}>
                                <div className="row d-flex align-items-center">
                                    <div className="col d-none d-lg-flex justify-content-center">
                                        <div className="">
                                            <h3 className="h3 fw-light">{data.smallTitle}</h3>
                                            <h1 className="h1">{data.bigTitle}</h1>
                                            <p className="pb-3">{data.summary}</p>
                                            <div className=""><a className="btn btn-light btn-ecomm"
                                                                 href="src/components/Store/home/presentation/components/HomeSlider#">Acheter maintenant
                                                <i className='bx bx-chevron-right'></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <img src={data.urlPicture} className="img-fluid" alt="..."/>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <a className="carousel-control-prev" href="src/components/Store/home/presentation/components/HomeSlider#carouselExampleDark" role="button"
                       data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="src/components/Store/home/presentation/components/HomeSlider#carouselExampleDark" role="button"
                       data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </a>
                </div>
            </div>
        </section>
    );
}

export default HomeSlider;