import React from "react";
import _data from "../../data";

class TeamMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            teamMember: {},
        };
    }

    handleSaveTeam(data) {
        console.log(data)
        localStorage.setItem('team', JSON.stringify(data));
    };

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
        this.setState({
            teamMember: _data.teamAllMembers,
        });
    }

    render() {
        return (
            <React.Fragment>
                <section className="team-member-section ptb-100">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7 col-md-8">
                                <div className="section-heading text-center mb-4">
                                    <h2>Rencontrez notre équipe</h2>
                                    <p className="lead">
                                        Une équipe bien formée et dynamique prêt à relever tout les défis
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {(this.state.teamMember.members || []).map((member, index) => {
                                return (
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="single-team-member position-relative my-lg-3 my-md-3 my-sm-0">
                                            <div className="team-image">
                                                <img
                                                    src={member.image}
                                                    alt="Team Members"
                                                    className="img-fluid rounded"
                                                />
                                            </div>
                                            <div
                                                className="team-info text-white rounded d-flex flex-column align-items-center justify-content-center">
                                                <h5 className="mb-0">{member.name}</h5>
                                                <h6>{member.designation}</h6>
                                                <ul className="list-inline team-social social-icon my-4 text-white">
                                                    <li className="list-inline-item">
                                                        <a href={member.twitter}>
                                                            <span className="ti-twitter"/>
                                                        </a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href={member.linkedin}>
                                                            <span className="ti-linkedin"/>
                                                        </a>
                                                    </li>
                                                </ul>
                                                <a href={"singleTeam"} onClick={() => this.handleSaveTeam(member)}
                                                   className="btn app-store-btn">Voir détail</a>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })};
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default TeamMember;
