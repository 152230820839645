import React, { Component } from "react";
import { connect } from "react-redux";
import { search } from "../../actions/index";
import _data from "../../data";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchData: "",
      article: {}
    };

    this.handleSearch = this.handleSearch.bind(this);
  }

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  handleSearch(event) {
    event.preventDefault();

    // // get action
    const searchAction = search(this.state);

    // // Dispatch the contact from data
    this.props.dispatch(searchAction);

    // // added delay to change button text to previous
    setTimeout(
      function () {
        // get action again to update state
        const searchAction = search(this.state);

        // Dispatch the contact from data
        this.props.dispatch(searchAction);

        // clear form data
        this.setState({
          searchData: "",
        });
      }.bind(this),
      3000
    );
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      article: _data.blogs
    });
  }

  render() {

    return (
      <React.Fragment>
        <div className="rs-inner-blog orange-color pt-100 pb-100 md-pt-70 md-pb-70">
          <div className="container">
            {(this.state.article.articles || []).map((article, index) => {
              return (

                <article className="post">
                  <div className="post-preview"><img src="img/blog/1-w.jpg" alt="article" className="img-fluid" />
                  </div>


                  <div className="post-wrapper">


                    <div className="post-header">
                      <h1 className="post-title">{article.title}</h1>
                      <ul className="post-meta">
                        <li>{article.date}</li>
                        <li>In <a href="#">Branding</a>, <a href="#">Design</a></li>
                        <li><a href="#">3 Comments</a></li>
                      </ul>
                    </div>
                    <div className="post-content">
                      <p>{article.content}</p>
                      <p>Exercitation photo booth stumptown tote bag Banksy, elit small batch freegan sed.
                        Craft beer elit seitan exercitation, photo booth et 8-bit kale chips proident
                        chillwave deep v laborum. Aliquip veniam delectus, Marfa eiusmod Pinterest in do
                        umami readymade swag. Selfies iPhone Kickstarter, drinking vinegar jean shorts
                        fixie consequat flexitarian four loko.</p>
                    </div>
                    <div className="post-footer">
                      <div className="post-tags"><a href="#">Lifestyle</a><a href="#">Music</a><a href="#">News</a><a
                        href="#">Travel</a></div>

                    </div>


                  </div>
                </article>

              );
            })}
          </div>
        </div>
      </React.Fragment>
    );

  }
}

export default connect(state => ({
  state,
}))(Blog);
