import React from "react";
import Header from "../Header/header";
import Footer from "../Footer";

class Training extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                <Header/>
                <div className="main">
                    <section className="hero-section hero-section-3 ptb-100">
                        <div className="circles">
                            <div className="point animated-point-1"/>
                            <div className="point animated-point-2"/>
                            <div className="point animated-point-3"/>
                            <div className="point animated-point-4"/>
                            <div className="point animated-point-5"/>
                            <div className="point animated-point-6"/>
                        </div>
                        <div className="container">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-md-6 col-lg-6">
                                    <div className="hero-content-left ptb-100">
                                        <h2 className="font-weight-bold">Des formations en technologies sur mesure.</h2>
                                        <p className="lead"/>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-5">
                                    <div className="hero-animation-img">
                                        <img className="img-fluid d-block m-auto animation-one"
                                             src="https://res.cloudinary.com/j2code/image/upload/v1648038625/website/training/undraw_online_learning_re_qw08_uttrqx.svg"
                                             width="150" alt="animation image"/>
                                        <img className="img-fluid d-none d-lg-block animation-two"
                                             src="https://res.cloudinary.com/j2code/image/upload/v1648039293/website/training/hero-animation-01_vfybma.svg"
                                             alt="animation image" width="120"/>
                                        <img className="img-fluid d-none d-lg-block animation-three"
                                             src="https://res.cloudinary.com/j2code/image/upload/v1648039357/website/training/Online_education_SVG_roxvk2.svg"
                                             alt="animation image" width="120"/>
                                        <img className="img-fluid d-none d-lg-block animation-four"
                                             src="https://res.cloudinary.com/j2code/image/upload/v1648039398/website/training/Coding_SVG_e3ebxg.svg"
                                             alt="animation image" width="200"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <img
                            src="https://res.cloudinary.com/j2code/image/upload/v1648039456/website/training/hero-bg-shape-2_oxzfcu.svg"
                            className="shape-image" alt="shape image"/>
                    </section>

                    <section className="promo-section ptb-100">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-7 col-md-8">
                                    <div className="section-heading text-center mb-5">
                                        <h3>Nos responsables thématiques disponibles pour : </h3>
                                        <p className="lead">
                                        La formation est mise en place soit dans  <br/> l’un de nos centres soit en ligne.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row equal">
                                <div className="col-md-4 col-lg-4">
                                    <div
                                        className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                                        <div className="circle-icon mb-5">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1648039833/website/training/Mobile_notification_PNG_rsace7.png"
                                                alt="pricing img" className="img-fluid"/>
                                        </div>
                                        <h5>DEVELOPPEMENT MOBILE</h5>
                                        <p>Savoir développer une application mobile native multiplateformes</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <div
                                        className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                                        <div className="circle-icon mb-5">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1648039919/website/training/Web_SVG_gklqyg.svg"
                                                alt="pricing img" className="img-fluid"/>
                                        </div>
                                        <h5>DEVELOPPEMENT WEB</h5>
                                        <p>Maîtrisez la conception des sites Internet avec HTML5/CSS3/Javascript ....</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <div
                                        className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                                        <div className="circle-icon mb-5">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1648040029/website/training/people_SVG_ofhxis.svg"
                                                alt="pricing img" className="img-fluid"/>
                                        </div>
                                        <h5>GESTION DE PROJET AGILE</h5>
                                        <p>Donner aux apprenants les bases du processus d’innovation, conférer une culture riche sur le fonctionnement et l’appropriation de la culture numérique et des technologies et usages à l’œuvre</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <div
                                        className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                                        <div className="circle-icon mb-5">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1648040029/website/training/people_SVG_ofhxis.svg"
                                                alt="pricing img" className="img-fluid"/>
                                        </div>
                                        <h5>INFOGRAPHIE</h5>
                                        <p>Donner aux apprenants les bases du processus d’innovation, conférer une culture riche sur le fonctionnement et l’appropriation de la culture numérique et des technologies et usages à l’œuvre</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <div
                                        className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                                        <div className="circle-icon mb-5">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1648040029/website/training/people_SVG_ofhxis.svg"
                                                alt="pricing img" className="img-fluid"/>
                                        </div>
                                        <h5>BUSINESS INTELLIGENCE</h5>
                                        <p>Donner aux apprenants les bases du processus d’innovation, conférer une culture riche sur le fonctionnement et l’appropriation de la culture numérique et des technologies et usages à l’œuvre</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <div
                                        className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                                        <div className="circle-icon mb-5">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1648040029/website/training/people_SVG_ofhxis.svg"
                                                alt="pricing img" className="img-fluid"/>
                                        </div>
                                        <h5>BIG DATA</h5>
                                        <p>Donner aux apprenants les bases du processus d’innovation, conférer une culture riche sur le fonctionnement et l’appropriation de la culture numérique et des technologies et usages à l’œuvre</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <div
                                        className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                                        <div className="circle-icon mb-5">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1648040029/website/training/people_SVG_ofhxis.svg"
                                                alt="pricing img" className="img-fluid"/>
                                        </div>
                                        <h5>UX / UI</h5>
                                        <p>Donner aux apprenants les bases du processus d’innovation, conférer une culture riche sur le fonctionnement et l’appropriation de la culture numérique et des technologies et usages à l’œuvre</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <div
                                        className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                                        <div className="circle-icon mb-5">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1648040029/website/training/people_SVG_ofhxis.svg"
                                                alt="pricing img" className="img-fluid"/>
                                        </div>
                                        <h5>COMMUNITY MANAGEMENT</h5>
                                        <p>Donner aux apprenants les bases du processus d’innovation, conférer une culture riche sur le fonctionnement et l’appropriation de la culture numérique et des technologies et usages à l’œuvre</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-4">
                                    <div
                                        className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                                        <div className="circle-icon mb-5">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1648040029/website/training/people_SVG_ofhxis.svg"
                                                alt="pricing img" className="img-fluid"/>
                                        </div>
                                        <h5>CLOUD COUNPUTING</h5>
                                        <p>Donner aux apprenants les bases du processus d’innovation, conférer une culture riche sur le fonctionnement et l’appropriation de la culture numérique et des technologies et usages à l’œuvre</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="features" className="feature-section ptb-100">
                        <div className="container">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-md-4">
                                    <div className="download-img">
                                        <img
                                            src="https://res.cloudinary.com/j2code/image/upload/v1648039507/website/training/course-image-1_adoxnk.png"
                                            alt="download" className="img-fluid"/>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="feature-contents section-heading">
                                        <h3>Restez à l'affût de l’actualité des Technologies</h3>
                                        <div className="feature-content-wrap">
                                            <ul className="nav nav-tabs feature-tab" data-tabs="tabs">
                                                <li className="nav-item">
                                                    <a className="nav-link active h6" href="#tab6-1" data-toggle="tab">
                                                        <span className="ti-user"/>
                                                        En présentiel
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link h6" href="#tab6-2" data-toggle="tab">
                                                        <span className="ti-video-camera"/>
                                                        En ligne
                                                    </a>
                                                </li>
                                            </ul>
                                            <div className="tab-content feature-tab-content">
                                                <div className="tab-pane active" id="tab6-1">
                                                    <ul className="list-unstyled">
                                                        <li className="py-2">
                                                            <div className="d-flex align-items-center">
                                                                <div>
                                                                    <div
                                                                        className="badge badge-circle badge-primary mr-3">
                                                                        <span className="ti-check"/>
                                                                    </div>
                                                                </div>
                                                                <div><p className="mb-0">Nous encourageons le développement des compétences techniques et transversales via nos formations.</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="py-2">
                                                            <div className="d-flex align-items-center">
                                                                <div>
                                                                    <div
                                                                        className="badge badge-circle badge-primary mr-3">
                                                                        <span className="ti-check"/>
                                                                    </div>
                                                                </div>
                                                                <div><p className="mb-0">Investir dans la formation technologique, active volonté reecrire une nouvelle page de sa reuissite.</p></div>
                                                            </div>
                                                        </li>
                                                        <li className="py-2">
                                                            <div className="d-flex align-items-center">
                                                                <div>
                                                                    <div
                                                                        className="badge badge-circle badge-primary mr-3">
                                                                        <span className="ti-check"/>
                                                                    </div>
                                                                </div>
                                                                <div><p className="mb-0">Nous travaillons avec des formateurs / consultants pour leurs expertises et connaissances opérationnelles</p></div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="tab-pane" id="tab6-2">
                                                    <ul className="list-unstyled">
                                                        <li className="py-2">
                                                            <div className="d-flex align-items-center">
                                                                <div>
                                                                    <div
                                                                        className="badge badge-circle badge-primary mr-3">
                                                                        <span className="ti-check"></span>
                                                                    </div>
                                                                </div>
                                                                <div><p className="mb-0">Tones of SASS variables</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="py-2">
                                                            <div className="d-flex align-items-center">
                                                                <div>
                                                                    <div
                                                                        className="badge badge-circle badge-primary mr-3">
                                                                        <span className="ti-check"/>
                                                                    </div>
                                                                </div>
                                                                <div><p className="mb-0">Create your own skin to match
                                                                    your brand</p></div>
                                                            </div>
                                                        </li>
                                                        <li className="py-2">
                                                            <div className="d-flex align-items-center">
                                                                <div>
                                                                    <div
                                                                        className="badge badge-circle badge-primary mr-3">
                                                                        <span className="ti-check"/>
                                                                    </div>
                                                                </div>
                                                                <div><p className="mb-0">Globally orchestrate tactical
                                                                    channels whereas
                                                                    bricks</p></div>
                                                            </div>
                                                        </li>
                                                        <li className="py-2">
                                                            <div className="d-flex align-items-center">
                                                                <div>
                                                                    <div
                                                                        className="badge badge-circle badge-primary mr-3">
                                                                        <span className="ti-check"/>
                                                                    </div>
                                                                </div>
                                                                <div><p className="mb-0">Use Gulp to prepare all assets
                                                                    for production</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="py-2">
                                                            <div className="d-flex align-items-center">
                                                                <div>
                                                                    <div
                                                                        className="badge badge-circle badge-primary mr-3">
                                                                        <span className="ti-check"/>
                                                                    </div>
                                                                </div>
                                                                <div><p className="mb-0">Collaboratively predominate
                                                                    vertical
                                                                    manufactured</p></div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="video-promo ptb-100 background-img"
                             style={{background: "url('https://res.cloudinary.com/j2code/image/upload/v1648039606/website/training/image-course-4_ehlez7.png')no-repeat center center / cover"}}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-6">
                                    <div className="video-promo-content mt-4 text-center">
                                        <a href="https://www.youtube.com/watch?v=DNGriIkiRhU"
                                           className="popup-youtube video-play-icon d-inline-block">
                                            <span className="ti-control-play color-primary"/></a>
                                        <h5 className="mt-4 text-white">Regarder la vidéo de présentation</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="pricing" className="package-section ptb-100">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <div className="section-heading text-center mb-5">
                                        <h2>Prix des packages</h2>
                                        <p className="lead">
                                            Maximize intuitive supply chains and end-to-end grow strategic process
                                            improvements
                                            vis-a-vis integrated resources.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md">
                                    <div className="card text-center single-pricing-pack p-5">
                                        <h5 className="mb-2">Développeur Flutter</h5>
                                        <div className="pricing-img mt-3 mb-4">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1648041706/website/training/png-clipart-dart-programming-language-flutter-object-oriented-programming-flutter-logo-class-fauna-removebg-preview_pqxsmv.png"
                                                alt="pricing img" className="img-fluid"/>
                                        </div>

                                        <div className="card-body p-0">
                                            <ul className="list-unstyled text-sm pricing-feature-list">
                                                <li>Le langage Dart</li>
                                                <li>Affichage de widgets Material</li>
                                                <li>Requêtes HTTP et HTTPS vers de API</li>
                                                <li>Google Maps dans vos application et géolocalisation des utilisateurs</li>
                                                <li>Publication sur les Stores</li>
                                            </ul>
                                            <div className="py-4 border-0 pricing-header">
                                                <div className="h1 text-center mb-0 color-secondary">250 000<span
                                                    className="price font-weight-bolder">XOF</span></div>
                                            </div>
                                            <a href="https://res.cloudinary.com/j2code/image/upload/v1648040261/website/training/syllabus/Syllabus_Flutter_ky8s58.pdf"
                                               className="btn outline-btn" target="_blank">Plus d'infos</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md">
                                    <div className="card text-center popular-price single-pricing-pack p-5">
                                        <h5 className="mb-2">Développeur Spring Boot</h5>
                                        <div className="pricing-img mt-3 mb-4">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1648041832/website/training/336-3363961_spring-boot-cloud-microservices-clipart-removebg-preview_c5acku.png"
                                                alt="pricing img" className="img-fluid"/>
                                        </div>

                                        <div className="card-body p-0">
                                            <ul className="list-unstyled text-sm pricing-feature-list">
                                                <li>Les fonctionnalités du framework</li>
                                                <li>Utilisation de JDBC et JPA</li>
                                                <li>Réalisation d'une application Web utilisant Spring MVC, et ajouter
                                                    des endpoints REST
                                                </li>
                                            </ul>
                                            <div className="py-4 border-0 pricing-header">
                                                <div className="h1 text-center mb-0 color-secondary">450 000<span
                                                    className="price font-weight-bolder">XOF</span></div>
                                            </div>
                                            <a href="https://res.cloudinary.com/j2code/image/upload/v1648040088/website/training/syllabus/Syllabus_Spring_Boot_waihmq.pdf"
                                               className="btn solid-btn" target="_blank">Plus d'infos</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md">
                                    <div className="card text-center single-pricing-pack p-5">
                                        <h5 className="mb-2">Chef de projet</h5>
                                        <div className="pricing-img mt-3 mb-4">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1648040029/website/training/people_SVG_ofhxis.svg"
                                                alt="pricing img" className="img-fluid"/>
                                        </div>

                                        <div className="card-body p-0">
                                            <ul className="list-unstyled text-sm pricing-feature-list">
                                                <li>Adapter l’organisation du projet à son contexte</li>
                                                <li>Pratiquer les outils de la conduite de projet</li>
                                                <li>Identifier des comportements « facilitateurs » ou gênants</li>
                                            </ul>
                                            <div className="py-4 border-0 pricing-header">
                                                <div className="h1 text-center mb-0 color-secondary">600 000<span
                                                    className="price font-weight-bolder">XOF</span></div>
                                            </div>
                                            <a href="https://res.cloudinary.com/j2code/image/upload/v1648040262/website/training/syllabus/Syllabus_Gestion_de_Projet_hj0r5e.pdf"
                                               className="btn outline-btn" target="_blank">Plus d'infos</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
                <Footer/>
            </React.Fragment>
        );
    }

}

export default Training;