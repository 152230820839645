import React from "react";
import {useEffect, useState} from "react";
import {db} from "../../../../../firebase/firebase";
import {productConverter} from "../../../../../models/Product";
import ItemQuantityProduct from "./ItemQuantityProduct";

const ItemProductByCategory = ({data}) => {

    const [product, setProduct] = useState([]);

    const fetchProduct = async () => {
        const response = db.collection("products").where("categoryId", "==", data)
            .withConverter(productConverter)
            .get();
        response.then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                const item = doc.data()
                item.id = doc.id;
                setProduct(arr => [...arr, item])
            });

        })
    }

    useEffect(async () => {
        await fetchProduct();
    }, [])

    return (
        <div>
            {(product || []).map((item, i) => (
                <a href="javascript:;" key={i}
                   className="list-group-item bg-transparent d-flex justify-content-between align-items-center">
                    {item.title}
                    <ItemQuantityProduct data={item.id}/>
                </a>
            ))}
        </div>
    );
}

export default ItemProductByCategory;