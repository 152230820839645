import React from "react";
import { connect } from "react-redux";

class HeroSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
    }

    render() {
        return (
            <React.Fragment>

                <section
                    className="hero-section position-relative min-vh-100 background-video-overly flex-column d-flex justify-content-center">
                    <video className="fit-cover w-100 h-100 position-absolute z--1" autoPlay muted loop id="myVideo">
                        <source
                            src="https://res.cloudinary.com/j2code/video/upload/v1647866355/website/img/movies/spotj2code_v963gy.mp4"
                            type="video/mp4" />
                    </video>
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-md-8 col-lg-8">
                                <div className="hero-content-left text-white text-center mt-5 ptb-100">
                                    <h1 className="text-white font-weight-bold"> Le carrefour des <br /> 👩🏻‍💻 geeks/geekettes 👩🏿‍💻
                                    </h1>
                                    <p className="lead">   Diligence - Ingéniosité - Excellence - Unité </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="client-section mt--80 d-none d-md-block d-lg-block">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div
                                    className="customer-slider-wrap client-section-wrap text-center gray-light-bg shadow-lg p-5">
                                    <span className="shape-left" />
                                    <span className="shape-right" />
                                    <div className="text-center">
                                        <p className="lead">La communauté compte <strong
                                            className="color-secondary font-weight-bold">+4000</strong> collaborateurs bâtissant l'avenir numérique des jeunes Africains.
                                        </p>
                                    </div>
                                    <div class="card-group">
                                        <div class="card-heroSection5">
                                        <img src="https://res.cloudinary.com/j2code/image/upload/v1691228314/website/img/piliers/wdstqxsuvzlpfnawuevd.png" class="card-img-top" alt="..." />
                                            <div class="card-body">
                                                <h5 class="card-title">Communauté</h5>
                                                    <p class="card-text">
                                                        <small class="text-muted">La connexion à la volonté de réussir, nous motive à 
                                                        la co-construction d'une communauté numérique solidaire.
                                                        </small>
                                                </p>
                                            </div>
                                        </div>

                                        <div class="card-heroSection5">
                                        <img src="https://res.cloudinary.com/j2code/image/upload/v1691226274/website/img/piliers/vw36qlbvbfjr8mpeippo.jpg" class="card-img-top" alt="cours" />
                                            <div class="card-body">
                                                <h5 class="card-title">Evénements</h5>
                                                <p class="card-text">
                                                    <small class="text-muted">Le rendez-vous du donner et du recevoir est la methode principale de nos évènements numériques.
                                                    </small>
                                                </p>
                                            </div>
                                        </div>

                                        <div class="card-heroSection5">
                                        <img src="https://res.cloudinary.com/j2code/image/upload/v1691225809/website/img/piliers/bxbkysvqbo7gmko0y2xh.png" class="card-img-top" alt="event" />
                                            <div class="card-body">
                                                <h5 class="card-title">Cours</h5>
                                                <p class="card-text">
                                                    <small class="text-muted">
                                                        L'éducation pour tous ensemble vers l'excellence numérique 
                                                        afin de voir naitre une prochaine génération d'élites.
                                                    </small>
                                                </p>
                                            </div>
                                        </div>
                                     
                                        <div class="card-heroSection5">
                                        <img src="https://res.cloudinary.com/j2code/image/upload/v1691228624/website/img/piliers/dw3p109hvvhalgkszcaf.jpg" class="card-img-top" alt="..." />
                                            <div class="card-body">
                                                <h5 class="card-title">Emplois</h5>
                                                <p class="card-text">
                                                    <small class="text-muted">La réalisation de votre potentiel 
                                                        dans des projets et/ou entreprises agiles afin d'implementer 
                                                        leur vision.
                                                    </small>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(state => (
    {
        state
    }
))(HeroSection);
