import React, { useContext, useRef, useState ,useEffect }  from "react";
import {Redirect, useHistory} from "react-router-dom";
import NavbarStore from "../Store/home/presentation/components/NavbarStore";
import firebase from "firebase";
import FooterStore from "../Store/home/presentation/components/FooterStore";

export default function Signin() {
//singin via email et password

	const signIn = (email, pwd) =>firebase.auth().signInWithEmailAndPassword(email, pwd)
		.then((userCredential) => {
			// Signed in
			var user = userCredential.user;
			// ...
		})
		.catch((error) => {
			var errorCode = error.code;
			var errorMessage = error.message;
		});
	const history = useHistory();
	const [validation, setValidation] = useState("");
	const inputs = useRef([]);
	const addInputs = (el) => {
		if (el && !inputs.current.includes(el)) {
			inputs.current.push(el);
		}
	};
	const formRef = useRef();
	const handleForm = async (e) => {
		e.preventDefault();
		console.log(inputs);
		try {
			const cred = await signIn(
				inputs.current[0].value,
				inputs.current[1].value
			);
			// à tester
			// formRef.current.reset();
			 // console.log(cred);
			history.push("/store")
		} catch {
			setValidation("Wopsy, email and/or password incorrect")
		}
	};
	const [currentUser, setCurrentUser] = useState();
	const [loadingData, setLoadingData] = useState(false);
	console.log("MAJ", currentUser);
	useEffect(() => {

		const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
			setCurrentUser(currentUser);
			setLoadingData(true)
		})

		return unsubscribe;

	}, [])

//singin via google
	const google = async () => {
		try {
            var provider = new firebase.auth.GoogleAuthProvider();
			await firebase.auth().signInWithRedirect(provider);
			history.push("/store");
		} catch {
			alert("impossible de se connecter via google.")
		}
	}
	firebase.auth()
		.getRedirectResult()
		.then((result) => {
			if (result.credential) {
				/** @type {firebase.auth.OAuthCredential} */
				var credential = result.credential;

				// This gives you a Google Access Token. You can use it to access the Google API.
				var token = credential.accessToken;
				// ...
			}
			// The signed-in user info.
			var user = result.user;
		}).catch((error) => {
		// Handle Errors here.
		var errorCode = error.code;
		var errorMessage = error.message;
		// The email of the user's account used.
		var email = error.email;
		// The firebase.auth.AuthCredential type that was used.
		var credential = error.credential;
		// ...
	});

//singup via facebook
	const facebook = async () => {
		try {
			var provider = new firebase.auth.FacebookAuthProvider();
			await firebase.auth().signInWithRedirect(provider);
			history.push("/store");
		} catch {
			alert("impossible de se connecter via google.")
		}
	}
	firebase.auth()
		.getRedirectResult()
		.then((result) => {
			if (result.credential) {
				/** @type {firebase.auth.OAuthCredential} */
				var credential = result.credential;

				// This gives you a Facebook Access Token. You can use it to access the Facebook API.
				var token = credential.accessToken;
				// ...
			}
			// The signed-in user info.
			var user = result.user;
		}).catch((error) => {
		// Handle Errors here.
		var errorCode = error.code;
		var errorMessage = error.message;
		// The email of the user's account used.
		var email = error.email;
		// The firebase.auth.AuthCredential type that was used.
		var credential = error.credential;
		// ...
	});
//redirection
	if (currentUser) {
		return <Redirect to='/store'/>;
	} else {
	return (
		<>
			<React.Fragment>
				<div className="bg-theme bg-theme2" style={{color: 'white'}}>
					<div className="wrapper">
						<NavbarStore/>
						<div className="page-wrapper ">
							<div className="page-content ">
								<section className="py-0 py-lg-2">
									<div className="container">
										<div
											className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
											<div className="row row-cols-1 row-cols-lg-1 row-cols-xl-2">
												<div className="col mx-auto">
													<div className="card mb-0">
														<div className="card-body">
															<div className="border p-4 rounded">
																<div className="text-center">
																	<h3 className="">Sign in</h3>
																	<p>Don't have an account yet? <a
																		href="/signup">Sign up here</a>
																	</p>
																</div>
																<div className="d-grid">
																	<button className="btn my-4 shadow-sm btn-light"
																	   onClick={ google }> <span
																		className="d-flex justify-content-center align-items-center">
									           						<i className="bx bxl-google"></i>
									  								<span>Sign In with Google</span>
																</span>
																	</button>
																	<button onClick={ facebook } className="btn btn-light"><i
																		className="bx bxl-facebook"></i>Sign Up with
																		Facebook
																	</button>
																</div>
																<div className="login-separater text-center mb-4"><span>OR SIGN UP WITH EMAIL</span>
																	<hr/>
																</div>
																<div className="form-body">
																	<form ref={formRef}  onSubmit={handleForm} className="row g-3">
																		<div className="col-12">
																			<label htmlFor="inputEmailAddress"
																				   className="form-label">Email
																				Address</label>
																			<input  ref={addInputs} type="email" className="form-control"
																				   id="inputEmailAddress"
																				   placeholder="Email Address"/>
																		</div>
																		<div className="col-12">
																			<label htmlFor="inputChoosePassword"
																				   className="form-label">Enter
																				Password</label>
																			<div className="input-group"
																				 id="show_hide_password">
																				<input  ref={addInputs} type="password"
																					   className="form-control border-end-0"
																					   id="inputChoosePassword"
																					   placeholder="Enter Password"/>
																			</div>
																		</div>
																		<div className="col-md-6">
																			<div className="form-check form-switch">
																				<input  ref={addInputs} className="form-check-input"
																					   type="checkbox"
																					   id="flexSwitchCheckChecked"
																					   checked/>
																					<label className="form-check-label"
																						   htmlFor="flexSwitchCheckChecked">Remember
																						Me</label>
																			</div>
																		</div>
																		<div className="col-md-6 text-end"><a
																			href="/ForgotPass">Forgot
																			Password ?</a>
																		</div>
																		<div className="col-12">
																			<div className="d-grid">
																				<button type="submit"
																						className="btn btn-light"><i
																					className='bx bx-user'></i>Sign In
																				</button>
																			</div>
																		</div>
																	</form>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</section>
							</div>
						</div>
						<FooterStore/>
						<a href="javaScript:;" className="back-to-top"><i className='bx bxs-up-arrow-alt'></i></a>
					</div>

					<a href="https://www.facebook.com/J2CODE" className="back-to-top">
						<i className='bx bxs-up-arrow-alt'></i>
					</a>
				</div>
			</React.Fragment>
		</>
		);
	}
}

