import React, {Component} from "react";
import _data from "../../data";

class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonial: {}
        };
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
        this.setState({
            testimonial: _data.testimonial
        });
    }

    render() {
        return (
            <React.Fragment>
                <section className="testimonial-section gray-light-bg ptb-100">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6">
                                <div className="section-heading mb-5">
                                    <span className="text-uppercase color-secondary sub-title">Les bienfaiteurs</span>
                                    <h4>Les pioniers de cette grande communauté</h4>
                                    <p className="lead">
                                        La transformation digital ne peut s'effectuer sans <br/> donner des reperes à cette nouvelle génération.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="owl-carousel owl-theme client-testimonial arrow-indicator">
                                    {(this.state.testimonial.comments || []).map(
                                        (comment, _index) => {
                                            return (
                                                <div className="item" key={comment.name}>
                                                    <div className="testimonial-quote-wrap">
                                                        <div className="media author-info mb-3">
                                                            <div className="author-img mr-3">
                                                                <img
                                                                    src={comment.image}
                                                                    alt="client"
                                                                    className="img-fluid rounded-circle"
                                                                />
                                                            </div>
                                                            <div className="media-body">
                                                                <h5 className="mb-0">{comment.name}</h5>
                                                                <span>{comment.company}</span>
                                                            </div>
                                                        </div>
                                                        <div className="client-say">
                                                            <p>
                                                                {" "}
                                                                <img
                                                                    src="https://res.cloudinary.com/j2code/image/upload/v1647866291/website/img/quote_jrhxyx.png"
                                                                    alt="quote"
                                                                    className="img-fluid"
                                                                />{" "}
                                                                {comment.comment}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Testimonial;
