import React from "react";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
    }

    render() {
        return (
            <React.Fragment>

                <footer className="footer-section">
                    <div className="footer-top pt-60 background-img-2" style={
                        {
                            background: "url('https://res.cloudinary.com/j2code/image/upload/v1647866281/website/img/footer-bg-1_vvrhdv.png')no-repeat center top / cover"
                        }
                    }>
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                                    <div className="footer-nav-wrap text-white">
                                        <img src="https://res.cloudinary.com/j2code/image/upload/v1647866303/website/img/logo-j2code_povnzd.png" alt="footer logo" width="120"
                                             className="img-fluid mb-3"/>
                                        <p>Le numérique est sans aucun doute un élément important dans le développement
                                            des nations au
                                            21e siècle.</p>

                                        <div className="social-list-wrap">
                                            <ul className="social-list list-inline list-unstyled">
                                                <li className="list-inline-item"><a
                                                    href="https://www.facebook.com/J2CODE"
                                                    target="_blank"
                                                    title="Facebook"><span className="ti-facebook"/></a></li>
                                                <li className="list-inline-item"><a
                                                    href="https://twitter.com/journeeducode"
                                                    target="_blank"
                                                    title="Twitter"><span className="ti-twitter"/></a></li>
                                                <li className="list-inline-item"><a
                                                    href="https://www.instagram.com/journeeducode/"
                                                    target="_blank"
                                                    title="Instagram"><span className="ti-instagram"/></a>
                                                </li>
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/company/j2code/?originalSubdomain=ci"
                                                    target="_blank"
                                                    title="linkedln"><span className="ti-linkedin"/></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-8">
                                    <div className="row">
                                        <div
                                            className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0 float-right">
                                        </div>
                                        <div
                                            className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0 float-right">
                                            <div className="footer-nav-wrap text-white">
                                                <h5 className="mb-3 text-white">Liens</h5>
                                                <ul className="list-unstyled support-list">
                                                    <li className="mb-2">
                                                        <a href="blog">Blog</a>
                                                    </li>
                                                    <li className="mb-2">
                                                        <a href="team">Notre équipe</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-4">
                                            <div className="footer-nav-wrap text-white">
                                                <h5 className="mb-3 text-white">Localisation</h5>
                                                <ul className="list-unstyled support-list">
                                                    <li className="mb-2 d-flex align-items-center">
                                                        <span className="ti-location-pin mr-2"/>
                                                        Riviera 2, Cocody, <br/>Abidjan (Côte d'Ivoire)
                                                    </li>
                                                    <li className="mb-2 d-flex align-items-center">
                                                        <span className="ti-mobile mr-2"/> <a
                                                        href="tel:+61283766284"> +225 0756661307</a></li>
                                                    <li className="mb-2 d-flex align-items-center">
                                                        <span className="ti-email mr-2"/><a
                                                        href="mailto:info@j2code.com"> info@j2code.com</a></li>
                                                    <li className="mb-2 d-flex align-items-center">
                                                        <span className="ti-world mr-2"/><a
                                                        href="#"> www.j2code.com</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-bottom border-gray-light mt-5 py-3">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 col-lg-7">
                                        <div className="copyright-wrap small-text">
                                            <p className="mb-0 text-white">
                                                © Développer par E-HUB, Tous droits réservés
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-5">
                                        <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                                            <ul className="list-inline">
                                                <li className="list-inline-item">
                                                    <a className="small-text" href="privacy">Politique de
                                                        confidentialité</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

            </React.Fragment>
        );
    }
}

export default Footer;
