import {useEffect, useState} from "react";
import {db} from "../../../../../firebase/firebase";
import React from "react";
import {productQuantityConverter} from "../../../../../models/ProductQuantity";

const ItemQuantityProduct = ({data}) => {

    const [quantity, setQuantity] = useState(0);

    const fetchProduct = async () => {
        await db.collection("ProductQuantities").where("productId", "==", data)
            .withConverter(productQuantityConverter)
            .get().then((querySnapshot) => {
                let cpt = parseInt(quantity);
                querySnapshot.forEach((doc) => {
                    if (doc.exists) {
                        const item = doc.data()
                        cpt += parseInt(item.quantity);
                    }
                });
                setQuantity(cpt)
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
    }

    useEffect(async () => {
        await fetchProduct();
    }, data)

    return (
        <span className="badge bg-light rounded-pill">{quantity}</span>
    );
}

export default ItemQuantityProduct;