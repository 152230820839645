import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/HeroSectionTeam";
import TeamSection from "../components/TeamMember/singleTeam";
import TrustedCompany from "../components/TrustedCompany/TrustedCompany";
import FooterSection from "../components/Footer";
import React from "react";

class SingleTeam extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            member: JSON.parse(localStorage.getItem('team'))
        }
    }

    render() {
        return (
            <React.Fragment>
                <Header/>
                <div className="main">
                    <HeroSection/>
                    <TeamSection member={this.state.member}/>
                    <TrustedCompany/>
                </div>
                <FooterSection/>
            </React.Fragment>
        );
    }
}

export default SingleTeam;
