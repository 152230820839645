class ProductQuantity {
    constructor(id, codeHex, productId, quantity, size) {
        this.id = id;
        this.codeHex = codeHex;
        this.productId = productId;
        this.quantity = quantity;
        this.size = size;
    }

    toString() {
        return this.id + ', ' + this.codeHex + ', ' + this.productId + ', ' + this.quantity + ', ' + this.size;
    }
}


//Firestore data converter
export const productQuantityConverter = {
    toFireStore: function (productQuantity) {
        return {
            id: productQuantity.id,
            codeHex: productQuantity.codeHex,
            productId: productQuantity.productId,
            quantity: productQuantity.quantity,
            size: productQuantity.size
        }
    },
    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);
        return new ProductQuantity(data.id, data.codeHex, data.productId, data.quantity, data.size);
    }
};