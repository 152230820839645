import React, {Fragment} from "react";

class FooterStore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Fragment>
                <section className="py-4 bg-dark-1">
                    <div className="container">
                        <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-4">
                            <div className="col">
                                <div className="footer-section1 mb-3">
                                    <h6 className="mb-3 text-uppercase">Contact</h6>
                                    <div className="address mb-3">
                                        <p className="mb-0 text-uppercase text-white">Adresse</p>
                                        <p className="mb-0 font-12">Riviera 2, Cocody, Côte d'Ivoire</p>
                                    </div>
                                    <div className="phone mb-3">
                                        <p className="mb-0 text-uppercase text-white">Téléphone</p>
                                        <p className="mb-0 font-13">Mobile : +225 0758661307</p>
                                    </div>
                                    <div className="email mb-3">
                                        <p className="mb-0 text-uppercase text-white">Email</p>
                                        <p className="mb-0 font-13">info@j2code.com</p>
                                    </div>
                                    <div className="working-days mb-3">
                                        <p className="mb-0 text-uppercase text-white">OUVERTURE</p>
                                        <p className="mb-0 font-13">Lundi - Samedi / 9:30 AM - 6:30 PM</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="row row-cols-1 row-cols-md-2 align-items-center">
                            <div className="col">
                                <p className="mb-0">© Développer par E-HUB, Tous droits réservés</p>
                            </div>
                            <div className="col text-end">
                                <div className="payment-icon">
                                    <div className="row row-cols-auto g-2 justify-content-end">
                                        <div className="col">
                                            <img src="https://res.cloudinary.com/j2code/image/upload/v1647864203/website/store/icons/orange_money_j6fy8j.png" alt=""/>
                                        </div>
                                        <div className="col">
                                            <img src="https://res.cloudinary.com/j2code/image/upload/v1647864202/website/store/icons/wave_sabkpe.png" alt=""/>
                                        </div>
                                        <div className="col">
                                            <img src="https://res.cloudinary.com/j2code/image/upload/v1647864201/website/store/icons/visa_d01jwi.png" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

export default FooterStore;