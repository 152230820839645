class ProductHomeSlider {
    constructor(id, smallTitle, bigTitle, summary, urlPicture, productId) {
        this.id = id;
        this.smallTitle = smallTitle;
        this.bigTitle = bigTitle;
        this.summary = summary;
        this.urlPicture = urlPicture;
        this.productId = productId;
    }

    toString() {
        return this.id + ', ' + this.smallTitle + ', ' + this.bigTitle + ', ' + this.summary + ', ' + this.urlPicture + ', ' + this.productId;
    }
}

//Firestore data converter
export const productHomeSliderConverter = {
    toFireStore: function (productHomeSlider) {
        return {
            id: productHomeSlider.id,
            smallTitle: productHomeSlider.smallTitle,
            bigTitle: productHomeSlider.bigTitle,
            summary: productHomeSlider.summary,
            urlPicture: productHomeSlider.urlPicture,
            productId: productHomeSlider.productId
        }
    },
    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);
        return new ProductHomeSlider(data.id, data.smallTitle, data.bigTitle, data.summary, data.urlPicture, data.productId);
    }
};