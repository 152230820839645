import React from "react";
import {connect} from "react-redux";

class Header extends React.Component {
    render() {
        return (
            <React.Fragment>
                <header className="header">
                    <nav className="navbar navbar-expand-lg fixed-top bg-transparent">
                        <div className="container">
                            <a className="navbar-brand" href="/">
                                <img
                                    src="https://res.cloudinary.com/j2code/image/upload/v1647866303/website/img/logo-j2code_povnzd.png"
                                    width="120"
                                    alt="logo"
                                    className="img-fluid"/></a>
                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                <span className="ti-menu"/>
                            </button>

                            <div className="collapse navbar-collapse main-menu" id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto">
                                    {/*<li className="nav-item">*/}
                                    {/*    <a className="nav-link page-scroll"*/}
                                    {/*             href="pages/blog-left-sidebar.html">Blog</a>*/}
                                    {/*</li>*/}
                                    <li className="nav-item">
                                        <a className="nav-link page-scroll" href="training">
                                            Formation
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link page-scroll" href="https://ecommercej2code.herokuapp.com">
                                            Boutique
                                        </a>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link page-scroll dropdown-toggle" href="#" id="navbarBlogPage"
                                           role="button"
                                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Événements
                                        </a>

                                        <div className="dropdown-menu submenu" aria-labelledby="navbarBlogPage">
                                            <a className="dropdown-item" href="events-j2c-2018">Edition 2018</a>
                                            <a className="dropdown-item" href="https://zoomsurtapassion.j2code.com">Zoom
                                                sur ta passion - 2022</a>
                                        </div>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="team">Équipe</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>
            </React.Fragment>
        );
    }
}

export default connect(state => ({}))(Header);
