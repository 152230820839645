import React from "react";
import {db} from "../../../../../firebase/firebase";
import {productFeaturedConverter} from "../../../../../models/ProductFeatured";
import QuickViewProduct from "./QuickViewProduct";


class FeaturedProducts extends React.Component {

    constructor() {
        super();
        this.state = {
            featureProduct: [], data: null
        }
    }

    addData = async (data) => {
        this.setState({data: data})
    };

    fetchFeatureProduct() {
        db.collection("ProductFeatureds")
            .withConverter(productFeaturedConverter)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const feature = doc.data();
                    const datas = this.state.featureProduct;
                    datas.push(feature);
                    this.setState({featureProduct: datas});
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
    }

    componentDidMount() {
        this.fetchFeatureProduct();
    }

    render() {
        return (<React.Fragment>
            <QuickViewProduct data={this.state.data}/>
            <section className="py-4">
                <div className="container">
                    <div className="d-flex align-items-center">
                        <h5 className="text-uppercase mb-0">ARTICLES A LA UNE</h5>
                        <a href="#" className="btn btn-light ms-auto rounded-0">Plus d'articles
                            <i className='bx bx-chevron-right'></i></a>
                    </div>
                    <hr/>
                    <div className="product-grid">
                        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
                            {(this.state.featureProduct || []).map((data, i) => (<div className="col">
                                <div className="card rounded-0 product-card" key={i}>
                                    <div className="card-header bg-transparent border-bottom-0">
                                        <div
                                            className="d-flex align-items-center justify-content-end gap-3">
                                            <a href="src/components/Store/home/presentation/components/FeaturedProducts#">
                                                <div className="product-compare"><span><i
                                                    className='bx bx-git-compare'></i> Compare</span>
                                                </div>
                                            </a>
                                            <a href="src/components/Store/home/presentation/components/FeaturedProducts#">
                                                <div className="product-wishlist"><i
                                                    className='bx bx-heart'></i>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <a href="src/components/Store/home/presentation/components/FeaturedProducts#">
                                        <img src={data.urlPicture} height={250} width={250}
                                             className="card-img-top"
                                             alt="..."/>
                                    </a>
                                    <div className="card-body">
                                        <div className="product-info">
                                            <a href="src/components/Store/home/presentation/components/FeaturedProducts#">
                                                <p className="product-catergory font-13 mb-1">{data.categoryName}</p>
                                            </a>
                                            <a href="src/components/Store/home/presentation/components/FeaturedProducts#">
                                                <h6 className="product-name mb-2">{data.productName}</h6>
                                            </a>
                                            <div className="d-flex align-items-center">
                                                <div className="mb-1 product-price"><span
                                                    className="me-1 text-decoration-line-through">{(data.oldPrice !== null && data.oldPrice > 0) ? (data.oldPrice + ' XOF') : ''}</span>
                                                    <span className="text-white fs-5">{data.price} XOF</span>
                                                </div>
                                                <div className="cursor-pointer ms-auto"><i
                                                    className="bx bxs-star text-white"></i>
                                                    <i className="bx bxs-star text-white"></i>
                                                    <i className="bx bxs-star text-white"></i>
                                                    <i className="bx bxs-star text-white"></i>
                                                    <i className="bx bxs-star text-white"></i>
                                                </div>
                                            </div>
                                            <div className="product-action mt-2">
                                                <div className="d-grid gap-2">
                                                    <a href="javascript:;" className="btn btn-light btn-ecomm">
                                                        <i className='bx bxs-cart-add'/>Ajouter au panier</a>
                                                    <a href="javascript:;" className="btn btn-link btn-ecomm"
                                                       onClick={async () => await this.addData(data)}
                                                       data-bs-toggle="modal" data-bs-target="#QuickViewProduct"><i
                                                        className='bx bx-zoom-in'/>Vue d'ensemble</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>))}
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>);
    }

}

export default FeaturedProducts;