import React from "react";
import _data from "../../data";

class TeamMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            member: props.member,
        };
    }

    render() {
        return (
            <React.Fragment>
                <section className="team-single-section ptb-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12 col-sm-12 col-lg-5">
                                <div className="team-single-img">
                                    <img
                                        src={this.state.member.image}
                                        alt="member"
                                        className="img-fluid rounded shadow-sm"
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-lg-6">
                                <div className="team-single-text">
                                    <div className="team-name mb-4">
                                        <h4 className="mb-1">{this.state.member.name}</h4>
                                        <span>{this.state.member.designation}</span>
                                    </div>
                                    <ul className="team-single-info">
                                        <li>
                                            <strong>Telephone:</strong>
                                            <span> {this.state.member.phone}</span>
                                        </li>
                                        <li>
                                            <strong>Email:</strong>
                                            <span> {this.state.member.email}</span>
                                        </li>
                                    </ul>
                                    <div className="text-content mt-20">
                                        <p>
                                            {this.state.member.description}
                                        </p>

                                    </div>
                                    <ul className="team-social-list list-inline mt-4">
                                        <li className="list-inline-item">
                                            <a href={this.state.member.twitter} className="color-primary">
                                                <span className="ti-twitter"/>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href={this.state.member.linkedin} className="color-primary">
                                                <span className="ti-linkedin"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-md-6 col-sm-6 col-12">
                                <div className="section-heading">
                                    <h5>Activités et savoir-faire</h5>
                                    <div className="section-heading-line-left"/>
                                </div>
                                <ul className="list-unstyled">
                                    {(this.state.member.activities || []).map((activity, index) => {
                                        return (
                                            <li className="py-2">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="badge badge-primary mr-3">
                                                            <span className="ti-check"/>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className="mb-0">{activity.name}</p>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <div className="col-md-6 col-sm-6 col-12">
                                <div className="section-heading mt-40">
                                    <h5>Savoir-faire professionnel</h5>
                                    <div className="section-heading-line-left"/>
                                </div>
                                {(this.state.member.solfSkills || []).map((solf, index) => {
                                    return (
                                        <div className="mt-3">
                                            <div className="progress-item">
                                                <div className="progress-title">
                                                    <h6>{solf.name}
                                                        <span className="float-right">
                                                            <span
                                                                className="progress-number">{solf.progress}</span>%</span>
                                                    </h6>
                                                </div>
                                                <div className="progress p-1">
                                                    <span style={{width: solf.progress + "%"}}>
                                                    <span className="progress-line"/>
                                                     </span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default TeamMember;
