import React from "react";
import Footer from "../Footer";
import Header from "../Header/header";

class Privacy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.setState({})
    }

    render() {
        return (
            <React.Fragment>
                <Header/>
                <div className="main">
                    <section className="hero-section ptb-100 background-img"
                             style={{
                                 background: "url('img/other-background.png')no-repeat center center / cover"
                             }}>
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-9 col-lg-7">
                                    <div className="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                                        <h1 className="text-white mb-0">Politique de confidentialité</h1>
                                        <div className="custom-breadcrumb">
                                            <ol className="breadcrumb d-inline-block bg-transparent list-inline py-0">
                                                <li className="list-inline-item breadcrumb-item">
                                                    <a href="/">Accueil</a>
                                                </li>
                                                <li className="list-inline-item breadcrumb-item">
                                                    <a href="/privacy">Politique de confidentialité</a>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="promo-section ptb-60">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-9">
                                    <div className="section-heading">
                                        <h2>CONDITIONS GÉNÉRALES D'UTILISATION DE J2CODE</h2>
                                        <p>
                                            <small>
                                                Les présentes conditions générales d'utilisation (dites « CGU ») ont
                                                pour objet
                                                l'encadrement juridique des modalités de mise à disposition et des
                                                services par la «
                                                J2CODE
                                                »​ et de définir les conditions d’accès et d’utilisation des services
                                                par
                                                « l'Utilisateur ». Les présentes CGU sont accessibles sur ​ J2CODE​.
                                            </small></p>

                                        <p>
                                            <small>
                                                Les CGU doivent être acceptées par tout Utilisateur souhaitant accéder à
                                                ​J2CODE​. Elles
                                                constituent le contrat entre la “​journée du code” et l'Utilisateur.
                                                L’accès à
                                                l’application
                                                par vous signifie son acceptation des présentes CGU. Il s’engage
                                                désormais à respecter
                                                les
                                                présentes conditions.
                                            </small></p>
                                        <p>
                                            <small>
                                                Toute inscription ou utilisation de l’application implique l'acceptation
                                                sans aucune
                                                réserve
                                                ni restriction des présentes CGU par vous. Lors de l'inscription via le
                                                Formulaire
                                                d’inscription, chaque utilisateur accepte expressément les présentes CGU
                                                en cochant la
                                                case
                                                précédant le texte suivant : « Je reconnais avoir lu et compris les CGU
                                                et je les
                                                accepte ».
                                            </small></p>
                                        <p>
                                            <small>
                                                En cas de non-acceptation des CGU stipulées dans le présent contrat,
                                                vous renoncer de
                                                facto
                                                à l'accès des services proposés par l’application.
                                            </small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="gray-light-bg ptb-100">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="single-faq">
                                        <h5>ARTICLE 1 : Mentions légales</h5>
                                        <p>
                                            <small>
                                                L'édition de ​J2CODE est assurée par l’équipe de la “journée du code” en
                                                Côte d’Ivoire,
                                                dont le siège social est situé à la ​Riviera,​ ​ ​info@j2codeci.com​,
                                                ​+225 58661307.
                                            </small></p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-faq">
                                        <h5>ARTICLE 2 : Accès au site</h5>
                                        <p><small>L’application ​J2CODE ​permet à l'utilisateur un accès gratuit à notre
                                            service
                                            suivant :</small></p>
                                        <p><small>- Postuler​ aux offres d’emploi IT et ​Recruter​ des profils
                                            informatique variés
                                            uniquement
                                            dans ls</small></p>
                                        <p><small>Une cartographie de compétence ​est ​accessible gratuitement via Play
                                            store en tout
                                            lieu à
                                            tout ​Utilisateur ayant un accès à Internet. Tous les frais supportés par
                                            l'​Utilisateur
                                            pour accéder au service (matériel informatique, logiciels, connexion
                                            Internet, etc.) sont à
                                            sa charge.</small></p>
                                        <p><small>Tout événement dû à un cas de force majeure ayant pour conséquence un
                                            dysfonctionnement de
                                            l’application ou serveur et sous réserve de toute interruption ou
                                            modification en cas de
                                            maintenance, n'engage pas la responsabilité de l'équipe de la “​Journée de
                                            Code​”. Dans ces
                                            cas, vous acceptez ainsi ne pas tenir rigueur à la dite équipe de toute
                                            interruption ou
                                            suspension de service, même sans préavis.
                                            Vous avez la possibilité de contacter l’équipe par messagerie électronique à
                                            l’adresse email
                                            de l’éditeur communiqué à l’ARTICLE 1.</small></p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-faq">
                                        <h5>ARTICLE 3 : Propriété intellectuelle</h5>
                                        <p>
                                            <small>Les marques, logos, signes ainsi que tous les contenus de
                                                l’application ​J2CODE
                                                ​(textes,
                                                images, son...) font l'objet d'une protection par le Code de la
                                                propriété
                                                intellectuelle et
                                                plus particulièrement par le droit d'auteur.
                                            </small>
                                        </p>
                                        <p>
                                            <small>
                                                En tant qu’utilisateur vous devez solliciter l'autorisation préalable
                                                de l’équipe de
                                                “la Journée du code” ​pour toute reproduction, publication, copie des
                                                différents contenus.
                                                Il s'engage à une utilisation des contenus de l’application dans un
                                                cadre
                                                strictement privé,
                                                toute utilisation à des fins commerciales et publicitaires est
                                                strictement
                                                interdite.
                                            </small>
                                        </p>
                                        <p>
                                            <small>
                                                Toute représentation totale ou partielle de la l’application ​J2CODE
                                                ​par
                                                quelque procédé
                                                que ce soit, sans l’autorisation expresse de l’exploitant de
                                                l’application
                                                constituerait une
                                                contrefaçon sanctionnée par le Code de la propriété intellectuelle.
                                            </small>
                                        </p>
                                        <p>
                                            <small>

                                                Il est rappelé conformément au Code de propriété intellectuelle que si
                                                vous
                                                reproduisez, une
                                                copie ou publie le contenu de notre application, vous devez citer
                                                l’auteur
                                                et sa
                                                source.
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-faq">
                                        <h5>ARTICLE 4 : Responsabilité</h5>
                                        <p><small>Les sources des informations diffusées dans l’application ​J2CODE
                                            ​sont
                                            réputées
                                            fiables mais
                                            ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.
                                            Les informations communiquées sont présentées à titre indicatif et général
                                            sans
                                            valeur
                                            contractuelle. Malgré des mises à jour régulières, l’application ​J2CODE ​ne
                                            peut
                                            être tenu
                                            responsable de la modification des dispositions administratives et
                                            juridiques
                                            survenant
                                            après la publication. De même, l’équipe ne peut être tenue responsable de
                                            l’utilisation et
                                            de l’interprétation de l’information contenue dans cette
                                            application.</small>
                                        </p>
                                        <p>
                                            <small>
                                                Vous devez vous assurer de garder votre mot de passe secret. Toute
                                                divulgation
                                                du
                                                mot de
                                                passe, quelle que soit sa forme, est interdite. Vous assumez pleinement
                                                les
                                                risques
                                                liés à
                                                l'utilisation de votre identifiant et mot de votre mot de passe.
                                                L’équipe en
                                                décline
                                                toute
                                                responsabilité.
                                            </small>
                                        </p>
                                        <p>
                                            <small>
                                                L’équipe de l’application ​J2CODE ne peut être tenu pour responsable
                                                d’éventuels
                                                virus
                                                qui
                                                pourraient infecter le portable ou tout matériel informatique de
                                                l’Internaute,
                                                suite
                                                à
                                                une
                                                utilisation, à l’accès, ou au téléchargement provenant de cette
                                                application.
                                            </small>
                                        </p>
                                        <p>
                                            <small>
                                                La responsabilité de l’équipe de l’application ​J2CODE ​ne peut être
                                                engagée
                                                en
                                                cas
                                                de
                                                force
                                                majeure ou du fait imprévisible et insurmontable d'un tiers.
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-faq">
                                        <h5>ARTICLE 5 : Liens hypertextes</h5>
                                        <p>
                                            <small>
                                                Des liens hypertextes peuvent être présents dans l’application ​J2CODE
                                                ​.
                                                Vous
                                                êtes
                                                informé
                                                qu’en cliquant sur ces liens, Vous sortirai de l’application ​J2CODE ​.
                                                Nous
                                                n’avons
                                                pas de
                                                contrôle sur les pages web sur lesquelles aboutissent ces liens et nous
                                                ne
                                                saurions,
                                                en
                                                aucun cas, être responsables de leur contenu.
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-faq">
                                        <h5>ARTICLE 6 : Cookies</h5>
                                        <p>
                                            <small>
                                                Vous êtes informé que lors de ses visites sur l’application, un cookie
                                                peut
                                                s’installer
                                                automatiquement sur son logiciel de navigation.
                                            </small>
                                        </p>
                                        <p>
                                            <small>
                                                Les cookies sont de petits fichiers stockés temporairement installés
                                                automatiquement
                                                par
                                                votre navigateur et qui sont nécessaires à l’utilisation de
                                                l’application
                                                ​J2CODE ​.
                                                Les
                                                cookies ne contiennent pas d’information personnelle et ne peuvent pas
                                                être
                                                utilisés
                                                pour
                                                identifier quelqu’un. Un cookie contient un identifiant unique, généré
                                                aléatoirement
                                                et donc
                                                anonyme. Certains cookies expirent à la fin de votre visite, d’autres
                                                restent.
                                                L’information contenue dans les cookies est utilisée pour améliorer le
                                                site,
                                                par
                                                exemple en
                                                :<br/>
                                                - permettant à un service de reconnaître votre appareil, pour que n’ait
                                                pas
                                                à
                                                fournir
                                                les
                                                mêmes informations à plusieurs reprises, par exemple remplir un
                                                formulaire
                                                ou
                                                une
                                                enquête.
                                                - mémorisant que vos identifiants et mot de passe sont déjà enregistrés,
                                                pour ne
                                                pas
                                                avoir à
                                                renseigner ces informations chaque nouvelle page.<br/>
                                                - surveillant comment vous vous servez des services, pour les rendre
                                                plus
                                                simples
                                                d’utilisation et allouer suffisamment de puissance pour s’assurer de
                                                leur
                                                réactivité.<br/>
                                                - analysant des données « rendues anonymes » pour aider à comprendre
                                                comment
                                                vous
                                                interagissez avec les différents aspects des services en ligne et donc
                                                permettre
                                                de les
                                                améliorer.
                                            </small>
                                        </p>
                                        <p>
                                            <small>
                                                En naviguant au sein de l’application, Vous les acceptez par un
                                                consentement
                                                explicite.
                                                A défaut d’acceptation, vous êtes informé que certaines fonctionnalités
                                                ou
                                                pages
                                                risquent de
                                                vous être refusées.
                                            </small>
                                        </p>
                                        <p>
                                            <small>
                                                Vous pourrez désactiver ce cookie par l’intermédiaire des paramètres
                                                figurant au
                                                sein de
                                                votre logiciel de navigation.
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-faq">
                                        <h5>ARTICLE 7 : Publication par l’utilisateur</h5>
                                        <p>
                                            <small>
                                                L’application ​J2CODE ​permettra aux utilisateurs de remonter
                                                des
                                                informations, poser des
                                                questions et faire des commentaires.
                                                Dans vos publications, vous vous engagez à respecter les règles de la
                                                Netiquette
                                                (règles de
                                                bonne conduite de l’internet) et les règles de droit en vigueur.
                                            </small>
                                        </p>
                                        <p>
                                            <small>
                                                L’équipe de la “​Journée du code” peut exercer une modération sur les
                                                publications
                                                et se
                                                réserve le droit de refuser leur mise en ligne, sans avoir à s’en
                                                justifier.
                                                Vous
                                                demeurez
                                                titulaire de l’intégralité de vos droits de propriété intellectuelle sur
                                                vos
                                                publications.
                                                Mais en publiant une publication sur ​l’application ​J2CODE ​, vous
                                                cédez à
                                                l’équipe
                                                ​de
                                                la
                                                ​“​Journée du code” le droit non exclusif et gratuit de représenter,
                                                reproduire,
                                                adapter,
                                                modifier, diffuser et distribuer sa publication, directement ou par un
                                                tiers
                                                autorisé,
                                                dans
                                                le monde entier, sur tout support (numérique ou physique), pour la durée
                                                de
                                                la
                                                propriété
                                                intellectuelle. Vous cédez notamment le droit d'utiliser votre
                                                publication
                                                sur
                                                internet
                                                et
                                                sur les réseaux de téléphonie mobile.
                                                L’équipe de la “​Journée du code” s'engage à faire figurer le nom de
                                                l’utilisateur à
                                                proximité de chaque utilisation de sa publication.
                                            </small>
                                        </p>
                                        <p>
                                            <small>
                                                Tout contenu que vous mettez en ligne est de votre seule et entière
                                                responsabilité.
                                                Vous
                                                vous engagez à ne pas mettre en ligne des contenus pouvant porter
                                                atteinte
                                                aux
                                                intérêts
                                                de
                                                tierces personnes. Tout recours en justice engagé par un tiers lésé
                                                contre
                                                L’équipe
                                                de
                                                la
                                                “​Journée du code” sera pris en charge par l'Utilisateur, auteur de la
                                                publication.
                                                Votre
                                                contenu peut être à tout moment et pour n'importe quelle raison supprimé
                                                ou
                                                modifié
                                                par
                                                ​L’équipe​ de la “​Journée du code” ​, sans préavis.
                                            </small>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-faq">
                                        <h5>ARTICLE 8 : Droit applicable et juridiction compétente</h5>
                                        <p>
                                            <small>
                                                La législation ivoirienne s'applique au présent contrat. En cas
                                                d'absence
                                                de
                                                résolution
                                                amiable d'un litige né entre les parties, les tribunaux ivoiriens seront
                                                seuls
                                                compétents
                                                pour statuer.
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer/>
            </React.Fragment>
        );
    }
}

export default Privacy;