import React from 'react';
import { NavLink } from "react-router-dom"
import NavbarStore from '../home/presentation/components/NavbarStore';
import Breadcrumb from './components/Breadcrumb';
import NavBarAccount from './components/NavBarAccount';

const AccountDetail = () => {
    return (
        <React.Fragment>
            <div className="bg-theme bg-theme2" style={{ color: 'white' }}>
                <div className="wrapper">
                    <NavbarStore />
                    <div className="page-wrapper">
                        <div className="page-content">
                            <Breadcrumb title="Détails du copmte" point="Détails" />
                            <section className="py-4">
                                <div className="container">
                                    <h3 className="d-none">Account</h3>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <NavBarAccount />
                                                <div class="col-lg-8">
                                                    <div class="card shadow-none mb-0">
                                                        <div class="card-body">
                                                            <form class="row g-3">
                                                                <div class="col-md-6">
                                                                    <label class="form-label">First Name</label>
                                                                    <input type="text" class="form-control" value="Madison" />
                                                                </div>
                                                                <div class="col-md-6">
                                                                    <label class="form-label">Last Name</label>
                                                                    <input type="text" class="form-control" value="Ruiz" />
                                                                </div>
                                                                <div class="col-12">
                                                                    <label class="form-label">Display Name</label>
                                                                    <input type="text" class="form-control" value="Madison Ruiz" />
                                                                </div>
                                                                <div class="col-12">
                                                                    <label class="form-label">Email address</label>
                                                                    <input type="text" class="form-control" value="madison.ruiz@gmail.com" />
                                                                </div>
                                                                <div class="col-12">
                                                                    <label class="form-label">Current Password</label>
                                                                    <input type="text" class="form-control" value="................." />
                                                                </div>
                                                                <div class="col-12">
                                                                    <label class="form-label">New Password</label>
                                                                    <input type="text" class="form-control" value="................." />
                                                                </div>
                                                                <div class="col-12">
                                                                    <label class="form-label">Confirm New Password</label>
                                                                    <input type="text" class="form-control" value="................." />
                                                                </div>
                                                                <div class="col-12">
                                                                    <button type="button" class="btn btn-light btn-ecomm">Save Changes</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>

                    <a href="javaScript:;" className="back-to-top"><i className='bx bxs-up-arrow-alt'></i></a>
                </div>
            </div>
        </React.Fragment>



    );
};

export default AccountDetail;