import React from "react";
import { connect } from "react-redux";

class PromoSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section className={"promo-section ptb-100 " + (this.props.removeTopMargin ? '' : 'mt-5')}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-10">
                <div className="section-heading mb-5">
                  <span className="text-uppercase color-secondary sub-title">NOTRE LABORATOIRE : </span>
                  <h5 className="mb-6">Là où idées se conçoivent</h5>
                  <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <img class="d-block w-100" src="https://res.cloudinary.com/j2code/image/upload/v1691774160/website/img/partenaires/typxdnel1i4l9igfkkeo.png" alt="logo J2Lab" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-10">
                <div className="section-heading mb-5">
                  <span className="text-uppercase color-secondary sub-title">NOTRE START UP : </span>
                  <h5 className="mb-6">Là où nos projets prennent vie.</h5>
                  <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                      <div class="carousel-item active">
                        <img class="d-block w-100" src="https://res.cloudinary.com/j2code/image/upload/v1691773622/website/img/partenaires/btsfysfitjxijnzmoiua.png" alt="logo J2Lab" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  state
}))(PromoSection);
