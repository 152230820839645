import React, {useEffect, useState} from "react";
import {db} from "../../../../../firebase/firebase";

const QuickViewProduct = ({data}) => {

    const [product, setProduct] = useState(null)
    const [size, setSize] = useState([])
    const [color, setColor] = useState([])
    const [picture, setPicture] = useState([])
    const [pictureSelected, setPictureSelected] = useState('');

    useEffect(() => {
        setProduct(data);
        fetchSize();
        fetchColors();
        fetchPicture();
    }, [data])

    const fetchPicture = () => {
        db.collection("ProductPicture")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const item = doc.data();
                    setPicture(arr => [...arr, item["urlPicture"]])
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
        setPictureSelected(picture[0])
    }

    const fetchColors = () => {
        db.collection("ProductColors")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const item = doc.data();
                    setColor(arr => [...arr, item["codeHex"]])
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
    }

    const fetchSize = () => {
        db.collection("ProductSizes")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const item = doc.data();
                    setSize(arr => [...arr, item["size"]])
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
    }

    const changePicture = (item) => {
        setPictureSelected(item)
    }

    return (<React.Fragment>
        {product === null ? (<div></div>) : (<div className="modal fade" id="QuickViewProduct">
            <div className="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-xl-down">
                <div className="modal-content bg-dark-4 rounded-0 border-0">
                    <div className="modal-body">
                        <button type="button" className="btn-close float-end" data-bs-dismiss="modal"/>
                        <div className="row g-0">
                            <div className="col-12 col-lg-6">
                                <div className="image-zoom-section">
                                    <div className="item">
                                        <img src={pictureSelected} className="img-fluid" alt=""/>
                                    </div>
                                    <div className="owl-thumbs d-flex justify-content-center" data-slider-id="1">
                                        {picture.map((item, i) => (
                                            <button className="owl-thumb-item" onClick={() => changePicture(item)}>
                                                <img key={i} src={item} className="" alt=""/>
                                            </button>
                                        ))}

                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="product-info-section p-3">
                                    <h3 className="mt-3 mt-lg-0 mb-0"
                                        style={{color: 'white'}}>{product.productName}</h3>
                                    <div className="d-flex align-items-center mt-3 gap-2">
                                        <h5 className="mb-0 text-decoration-line-through text-light-3">{(product.oldPrice !== null && product.oldPrice > 0) ? (product.oldPrice + ' XOF') : ''}</h5>
                                        <h4 className="mb-0" style={{color: 'white'}}>{product.price} XOF</h4>
                                    </div>
                                    <div className="mt-3">
                                        <h6>Description :</h6>
                                        <p className="mb-0">Vidssdrgil Abloh’s Off-White is a
                                            streetwear-inspired collection that continues to break away
                                            from the conventions of mainstream fashion. Made in Italy,
                                            these black and brown Odsy-1000 low-top sneakers.</p>
                                    </div>
                                    <dl className="row mt-3">
                                        <dt className="col-sm-3">Livraison</dt>
                                        <dd className="col-sm-9">Côte d'Ivoire, Togo et Canada</dd>
                                    </dl>
                                    <div className="row row-cols-auto align-items-center mt-3">
                                        <div className="col">
                                            <label className="form-label">Quantité</label>
                                            <select className="form-select form-select-sm">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5</option>
                                            </select>
                                        </div>
                                        <div className="col">
                                            <label className="form-label">Taille</label>
                                            <select className="form-select form-select-sm">
                                                {size.map((item, i) => (i !== 1 ? (
                                                    <option value={item} key={i}>{item}</option>) : (<bloc/>)))}
                                            </select>
                                        </div>
                                        <div className="col">
                                            <label className="form-label">Couleurs</label>
                                            <div className="color-indigators d-flex align-items-center gap-2">
                                                {color.map((item, i) => (i !== 1 ? (<div key={i}
                                                                                         className="color-indigator-item bg-primary"></div>) : (
                                                    <bloc/>)))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex gap-2 mt-3">
                                        <a href="#" className="btn btn-white btn-ecomm">
                                            <i className="bx bxs-cart-add"></i>Ajouter au panier</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)}
    </React.Fragment>);
}

export default QuickViewProduct;