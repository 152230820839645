import React, {useState} from "react";
import FooterStore from "../home/presentation/components/FooterStore";
import NavbarStore from "../home/presentation/components/NavbarStore";

const DetailProductsPage = () => {

    const [showDescriptionTag, setShowDescriptionTag] = useState(true)
    const [showDeliveryTag, setShowDeliveryTag] = useState(false)

    const activeDescriptionTag = () => {
        setShowDescriptionTag(true);
        setShowDeliveryTag(false);
    }

    const activeDeliveryTag = () => {
        setShowDescriptionTag(false);
        setShowDeliveryTag(true);
    }

    return (<React.Fragment>
        <div className="bg-theme bg-theme2" style={{color: 'white'}}>
            <div className="wrapper">
                <NavbarStore/>
                <div className="page-wrapper">
                    <div className="page-content">
                        <section className="py-3 border-bottom d-none d-md-flex">
                            <div className="container">
                                <div className="page-breadcrumb d-flex align-items-center">
                                    <h3 className="breadcrumb-title pe-3">Pull Geek</h3>
                                </div>
                            </div>
                        </section>
                        <section className="py-4">
                            <div className="container">
                                <div className="product-detail-card">
                                    <div className="product-detail-body">
                                        <div className="row g-0">
                                            <div className="col-12 col-lg-5">
                                                <div className="image-zoom-section">
                                                    <div className="item">
                                                        <img
                                                            src="https://res.cloudinary.com/j2code/image/upload/v1647864210/website/store/product-gallery/pull-rouge_dxvw4c.png"
                                                            className="img-fluid"
                                                            alt=""/>
                                                    </div>

                                                    <div className="owl-thumbs d-flex justify-content-center"
                                                         data-slider-id="1">
                                                        <button className="owl-thumb-item">
                                                            <img
                                                                src="https://res.cloudinary.com/j2code/image/upload/v1647864210/website/store/product-gallery/pull-rouge_dxvw4c.png"
                                                                className="" alt=""/>
                                                        </button>
                                                        <button className="owl-thumb-item">
                                                            <img
                                                                src="https://res.cloudinary.com/j2code/image/upload/v1647864209/website/store/product-gallery/pull-blanc_mkpwgi.png"
                                                                className="" alt=""/>
                                                        </button>
                                                        <button className="owl-thumb-item">
                                                            <img
                                                                src="https://res.cloudinary.com/j2code/image/upload/v1647870968/website/store/product-gallery/pull_bleu_jqul4n.png"
                                                                className="" alt=""/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-7">
                                                <div className="product-info-section p-3">
                                                    <h3 className="mt-3 mt-lg-0 mb-0">Pull Geek</h3>

                                                    <div className="d-flex align-items-center mt-3 gap-2">
                                                        <h5 className="mb-0 text-decoration-line-through text-light-3">15.000
                                                            XOF</h5>
                                                        <h4 className="mb-0">13.000 XOF</h4>
                                                    </div>
                                                    <div className="mt-3">
                                                        <h6>Discription :</h6>
                                                        <p className="mb-0">Compagnon de choix pour vos activités en
                                                            plein
                                                            air et vos moments de détente, ce pull à capuche
                                                            confortable
                                                            ne vous quittera plus.</p>
                                                    </div>
                                                    <dl className="row mt-3">
                                                        <dt className="col-sm-3">Livraison</dt>
                                                        <dd className="col-sm-9">Côte d'Ivoire, Togo, Canada</dd>
                                                    </dl>
                                                    <div className="row row-cols-auto align-items-center mt-3">
                                                        <div className="col">
                                                            <label className="form-label">Taile</label>
                                                            <select className="form-select form-select-sm">
                                                                <option>S</option>
                                                                <option>M</option>
                                                                <option>L</option>
                                                                <option>Xl</option>
                                                                <option>XXL</option>
                                                            </select>
                                                        </div>
                                                        <div className="col">
                                                            <label className="form-label">Couleurs</label>
                                                            <div
                                                                className="color-indigators d-flex align-items-center gap-2">
                                                                <div
                                                                    className="color-indigator-item bg-danger"></div>
                                                                <div
                                                                    className="color-indigator-item bg-white"></div>
                                                                <div
                                                                    className="color-indigator-item bg-primary"></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <hr/>
                                                    <div className="product-sharing">
                                                        <ul className="list-inline">
                                                            <li className="list-inline-item"><a
                                                                href="https://www.facebook.com/J2CODE"><i
                                                                className='bx bxl-facebook'></i></a>
                                                            </li>
                                                            <li className="list-inline-item"><a
                                                                href="https://twitter.com/journeeducode"><i
                                                                className='bx bxl-twitter'></i></a>
                                                            </li>
                                                            <li className="list-inline-item"><a href="javascript:;"><i
                                                                className='bx bxl-instagram'></i></a>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="py-4">
                            <div className="container">
                                <div className="product-more-info">
                                    <ul className="nav nav-tabs mb-0" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a className={showDescriptionTag ? "nav-link active" : "nav-link"}
                                               data-bs-toggle="tab"
                                               role="tab"
                                               onClick={() => activeDescriptionTag()}
                                               aria-selected="true">
                                                <div className="d-flex align-items-center">
                                                    <div className="tab-title text-uppercase fw-500">Description
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a className={showDeliveryTag ? "nav-link active" : "nav-link"}
                                               data-bs-toggle="tab"
                                               role="tab"
                                               onClick={() => activeDeliveryTag()}
                                               aria-selected="false">
                                                <div className="d-flex align-items-center">
                                                    <div className="tab-title text-uppercase fw-500">Livraison</div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className="tab-content pt-3">
                                        <div
                                            className={showDescriptionTag ? "tab-pane fade show active" : "tab-pane fade show"}
                                            role="tabpanel">
                                            <p>Raw denim you probably haven't heard of them jean shorts Austin.
                                                Nesciunt tofu
                                                stumptown aliqua, retro synth master cleanse. Mustache cliche
                                                tempor,
                                                williamsburg carles vegan helvetica. Reprehenderit butcher retro
                                                keffiyeh
                                                dreamcatcher synth. Cosby sweater eu banh mi, qui irure terry
                                                richardson ex
                                                squid. Aliquip placeat salvia cillum iphone. Seitan aliquip quis
                                                cardigan
                                                american apparel, butcher voluptate nisi.</p>
                                            <ul>
                                                <li>Not just for commute</li>
                                                <li>Branded tongue and cuff</li>
                                                <li>Super fast and amazing</li>
                                                <li>Lorem sed do eiusmod tempor</li>
                                            </ul>
                                            <p className="mb-1">Cosby sweater eu banh mi, qui irure terry richardson
                                                ex squid.
                                                Aliquip placeat salvia cillum iphone.</p>
                                            <p className="mb-1">Seitan aliquip quis cardigan american apparel,
                                                butcher voluptate
                                                nisi.</p>
                                        </div>
                                        <div
                                            className={showDeliveryTag ? "tab-pane fade show active" : "tab-pane fade show"}
                                            role="tabpanel">
                                            <p>Food truck fixie locavore, accusamus mcsweeney's marfa nulla
                                                single-origin coffee
                                                squid. Exercitation +1 labore velit, blog sartorial PBR leggings
                                                next level wes
                                                anderson artisan four loko farm-to-table craft beer twee. Qui photo
                                                booth
                                                letterpress, commodo enim craft beer mlkshk aliquip jean shorts
                                                ullamco ad vinyl
                                                cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna
                                                delectus
                                                mollit. Keytar helvetica VHS salvia yr, vero magna velit sapiente
                                                labore
                                                stumptown. Vegan fanny pack odio cillum wes anderson 8-bit,
                                                sustainable jean
                                                shorts beard ut DIY ethical culpa terry richardson biodiesel. Art
                                                party
                                                scenester stumptown, tumblr butcher vero sint qui sapiente accusamus
                                                tattooed
                                                echo park.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <FooterStore/>
            </div>
        </div>
    </React.Fragment>)
}

export default DetailProductsPage