import React from "react";
import Header from "../../../Header/header";
import FooterSection from "../../../Footer";

class EventJ2c2018 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment>
                <Header/>
                <div className="main">

                    <section className="hero-section hero-section-3 ptb-100 secondary-bg">
                        <div className="circles">
                            <div className="point animated-point-1"/>
                            <div className="point animated-point-2"/>
                            <div className="point animated-point-3"/>
                            <div className="point animated-point-4"/>
                            <div className="point animated-point-5"/>
                            <div className="point animated-point-6"/>
                        </div>
                        <div className="container" id="animation-area-1">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                                    <div className="hero-content section-title text-center text-lg-left my-5 mt-lg-0">
                                        <span
                                            className="text-uppercase color-secondary font-weight-bold">Esactic - 2018</span>
                                        <h1 className="font-weight-bold">La place du développeur dans le processus
                                            d'émergence de la Côte
                                            d'Ivoire</h1>
                                    </div>
                                </div>
                                <div className="col-9 col-md-7 col-lg-5 offset-xl-1 align-self-sm-end">
                                    <div className="hero-img position-relative">
                                        <div className="image-bg-positioning">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453694/website/events/j2c/2018/image-j2code-2018_ejyluj.png"
                                                alt=""
                                                className="img-fluid"/>
                                        </div>
                                        <div className="animation-item d-none d-md-block d-lg-block">
                                            <div
                                                className="p-4 w-75 rounded-custom d-flex white-bg hero-animated-card-1">
                                                <p className="gr-text-11 mb-0 text-mirage-2">“Les leaders qui font
                                                    cavalier seul, cela
                                                    n’existe pas”</p>
                                                <div className="small-card-img ml-3">
                                                    <img
                                                        src="https://res.cloudinary.com/j2code/image/upload/v1647866293/website/img/team/team-1_sqbzwe.png"
                                                        alt="" width="80px"
                                                        className="rounded-circle img-fluid"/>
                                                </div>
                                            </div>
                                            <div
                                                className="p-4 w-75 rounded-custom d-flex secondary-bg hero-animated-card-2">
                                                <div className="small-card-img mr-3 text-white">
                                                    <img
                                                        src="https://res.cloudinary.com/j2code/image/upload/v1647866279/website/img/speaker/speaker-1_nhpcbh.png"
                                                        alt="" width="80px"
                                                        className="rounded-circle img-fluid"/>
                                                </div>
                                                <p className="gr-text-11 mb-0 text-white">“la gente féminine a sa part à
                                                    jouer dans le
                                                    développement de l’écosystème numérique”</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="pb-100 pt-100">
                        <div className="container">

                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <div className="section-heading text-center mb-5">
                                        <span className="text-uppercase color-secondary sub-title">STATISTIQUE DE LA DEUXIEME ÉDITION</span>
                                        <h2>LA PLACE DU DÉVELOPPEUR DANS LE PROCESSUS D’ÉMERGENCE DE LA CÔTE
                                            D’IVOIRE</h2>
                                        <p>Le centre de formation #10000Codeurs en sa qualité de sponsor officiel de
                                            cette seconde
                                            édition en collaboration avec le Comité des Etudiants de l’ #ESATIC (C2E) à
                                            organisé le
                                            Samedi 27 Octobre 2018 de 08H00 à 17h30, du Le rendez-vous rassemblant
                                            développeurs,
                                            passionnés des nouvelles technologies et les intégrateurs de solutions
                                            numériques la «
                                            journée du code ». En effet, ayant pour principal objet la promotion de
                                            l’innovation à
                                            travers les technologies de l’information et des télécommunications dans les
                                            pays émergents
                                            en offrant des panels et/ou des formations en programmation sur diverses
                                            plateformes
                                            technologiques.</p>

                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
                                    <div className="single-fact p-3 text-center">
                                        <h3 className="title display-3 font-weight-bolder">4</h3>
                                        <p className="mb-0">PANELISTE</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
                                    <div className="single-fact p-3 text-center">
                                        <h3 className="title display-3 font-weight-bolder">406 </h3>
                                        <p className="mb-0">PARTICIPANTS</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
                                    <div className="single-fact p-3 text-center">
                                        <h3 className="title display-3 font-weight-bolder">299</h3>
                                        <p className="mb-0">HOMMES</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
                                    <div className="single-fact p-3 text-center">
                                        <h3 className="title display-3 font-weight-bolder">26</h3>
                                        <p className="mb-0">SPEAKERS</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
                                    <div className="single-fact p-3 text-center">
                                        <h3 className="title display-3 font-weight-bolder">306</h3>
                                        <p className="mb-0">ETUDIANTS</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
                                    <div className="single-fact p-3 text-center">
                                        <h3 className="title display-3 font-weight-bolder">107</h3>
                                        <p className="mb-0">FEMMES</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
                                    <div className="single-fact p-3 text-center">
                                        <h3 className="title display-3 font-weight-bolder">24</h3>
                                        <p className="mb-0">ATELIERS</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
                                    <div className="single-fact p-3 text-center">
                                        <h3 className="title display-3 font-weight-bolder">97</h3>
                                        <p className="mb-0">TRAVAILLEURS</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
                                    <div className="single-fact p-3 text-center">
                                        <h3 className="title display-3 font-weight-bolder">9</h3>
                                        <p className="mb-0">ENTREPRENEUR</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="about" className="about-us ptb-100 gray-light-bg">
                        <div className="container">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-md-4">
                                    <div className="about-content-right">
                                        <img
                                            src="https://res.cloudinary.com/j2code/image/upload/v1647453697/website/events/j2c/2018/j2c-2018-1_prh6a7.png"
                                            alt="about us"
                                            className="img-fluid"/>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="about-content-left section-heading">
                                        <h2>OBJECTIFS ET INTÉRÊT</h2>

                                        <div className="single-feature mb-4 mt-5">
                                            <div className="icon-box-wrap d-flex align-items-center mb-2">
                                                <p className="mb-0">Créée en 2017 par un groupe de Développeurs
                                                    Ivoiviens, J2CODE aimerait
                                                    voir naître un environnement dans lequel les populations des pays
                                                    émergents
                                                    utilisent les possibilités offertes par la programmation
                                                    informatique. Pour mieux
                                                    accomplir cette mission elle a décidé de focaliser ses actions sur
                                                    les points
                                                    suivants :</p>
                                            </div>
                                            <div className="icon-box-wrap d-flex align-items-center mb-2">
                                                <p className="mb-0">• Sensibiliser la masse sur l’importance du coding
                                                    dans l’avènement d’un
                                                    monde
                                                    axé sur le numérique.</p>
                                            </div>

                                            <div className="icon-box-wrap d-flex align-items-center mb-2">
                                                <p className="mb-0">• Encourager l’éducation populaire en matière de
                                                    programmation
                                                    informatique à
                                                    travers des formations, des ateliers d’initiation et
                                                    d’apprentissage.</p>
                                            </div>

                                            <div className="icon-box-wrap d-flex align-items-center mb-2">
                                                <p className="mb-0">• Permettre aux participants à la formation de
                                                    mettre en pratique les
                                                    connaissances
                                                    acquises par la création et la participation à des projets Open
                                                    Source.</p>
                                            </div>

                                            <div className="icon-box-wrap d-flex align-items-center mb-2">
                                                <p className="mb-0">• Créer une grande communauté composée de
                                                    développeurs, de concepteurs
                                                    de
                                                    logiciels ivoiriens afin d’encourager le transfert de connaissance
                                                    et susciter des
                                                    vocations
                                                    auprès des générations futures.</p>
                                            </div>

                                        </div>

                                        <div className="single-feature mb-4 mt-5">
                                            <div className="icon-box-wrap d-flex align-items-center mb-2">
                                                <p className="mb-0">La J2CODE 2018 réunira plus de 1000 participants, à
                                                    l’Ecole Supérieure
                                                    Africaine des Technologies de l’information et de la Communication
                                                    (ESATIC) pour
                                                    :</p>
                                            </div>
                                            <div className="icon-box-wrap d-flex align-items-center mb-2">
                                                <p className="mb-0">Une journée (06 Octobre 2018) d’atelier, de
                                                    conférences, une table ronde
                                                    et un instant partenaires.
                                                    Une journée complète de formation dans diverses technologies dont :
                                                    Java, Android,
                                                    IOS (IPhone et IPad), HTML5, Cloud, Web Design,la 3d etc.</p>
                                            </div>

                                            <div className="icon-box-wrap d-flex align-items-center mb-2">
                                                <p className="mb-0">Cet événement représente d’une part, une véritable
                                                    occasion de mettre en
                                                    valeurs les technologies et les talents disponibles en Côte d’Ivoire
                                                    et dans la
                                                    région d’Afrique Occidentale. Et d’autre part, l’opportunité de
                                                    découvrir et
                                                    d’apprendre davantage sur les toutes dernières innovations en
                                                    développements
                                                    d’applications mobiles et d’entreprise.</p>
                                            </div>

                                            <div className="icon-box-wrap d-flex align-items-center mb-2">
                                                <p className="mb-0">C’est l’occasion aussi de mettre en avant les
                                                    startups et entreprises
                                                    technologiques qui sont présentent en Côte d’Ivoire. De plus, J2CODE
                                                    vous fait
                                                    découvrir les meilleures pratiques en vigueur dans l’industrie du
                                                    logiciel ainsi que
                                                    les dernières avancées technologiques.</p>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="blog" className="our-blog-section ptb-100 primary-bg">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="section-heading mb-5 text-white">
                                        <h2 className="text-white">Vos temps forts</h2>
                                        <p>
                                            Enthusiastically drive revolutionary opportunities before emerging
                                            leadership. Distinctively
                                            transform tactical methods of empowerment via resource sucking core.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="single-blog-card card border-0 shadow-sm white-bg">
                                        <iframe width="100%" height="250"
                                                src="https://www.youtube.com/embed/mtGxLQWWAeQ"
                                                title="YouTube video player"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen/>
                                        <div className="card-body">
                                            <h3 className="h5 card-title">Interview #J2CODE : Part 2- Léger DJIBA</h3>
                                            <p className="card-text">Quatrième intervenant pour le panel de l'édition
                                                2018
                                                de la Journée du
                                                code est #Léger_DJIBA, Solution Architect & Technical Lead Consultant à
                                                la Banque
                                                Africaine de Développement.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="single-blog-card card border-0 shadow-sm white-bg">
                                        <iframe width="100%" height="250"
                                                src="https://www.youtube.com/embed/WAWMaWUTjt8"
                                                title="YouTube video player"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen/>
                                        <div className="card-body">
                                            <h3 className="h5 card-title">Interview #J2CODE : Part 1 - SAHOUREY
                                                KONAN</h3>
                                            <p className="card-text">Découvrez les impressions de notre panéliste,
                                                Sahourey
                                                Konan, à la
                                                suite de la 2nde édition de la Journée du code.
                                                #J2Code #MaintenantCodons</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="single-blog-card card border-0 shadow-sm white-bg">
                                        <iframe width="100%" height="250"
                                                src="https://www.youtube.com/embed/y3ga3d0auOw"
                                                title="YouTube video player"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen/>
                                        <div className="card-body">
                                            <h3 className="h5 card-title">J2Code 2018 Teaser</h3>
                                            <p className="card-text">Musique utilisée dans cette vidéo</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section id="team-log" className="team-member-section ptb-100">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-7 col-md-8">
                                    <div className="section-heading text-center mb-5">
                                        <span className="text-uppercase color-secondary sub-title">L’EQUIPE</span>
                                        <h2>TEAM COMMUNICATION</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-lg-3 col-sm-6">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453638/website/events/j2c/2018/yannick_cabedx.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">Yannick E N’guessan</h5>
                                            <h6>Designer Web</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/emnick/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453614/website/events/j2c/2018/paul_bene_qx2spt.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">Paul Roland Bene</h5>
                                            <h6>Senior Community Manager</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a href="#"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453658/website/events/j2c/2018/yves_ipzqjs.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">Jean Yves B AGNISSAN</h5>
                                            <h6>Digital Marketing Manager</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/jean-yves-bertrand-agnissan/"><span
                                                    className="ti-facebook"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453616/website/events/j2c/2018/maruis_qqndgh.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">Marius Kwadyo</h5>
                                            <h6>Tech Video Producer</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/mariuskwadyo/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section id="team-log" className="team-member-section ptb-50">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-7 col-md-8">
                                    <div className="section-heading text-center mb-5">
                                        <span className="text-uppercase color-secondary sub-title">L’EQUIPE</span>
                                        <h2>TEAM LOGISTIQUE</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-sm-6">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453584/website/events/j2c/2018/cisse_mhjhgw.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">Toumoutou Cisse</h5>
                                            <h6>Java full stack developper</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/abdoulaye-toumoutou-cisse/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453592/website/events/j2c/2018/gbobehi_fnryl7.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">Philémon GLOBLEHI</h5>
                                            <h6>Développeur Java, Web et Mobile</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/philemon-globlehi/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="team-org" className="team-member-section ptb-100">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-7 col-md-8">
                                    <div className="section-heading text-center mb-5">
                                        <span className="text-uppercase color-secondary sub-title">L’EQUIPE</span>
                                        <h2>TEAM ORGANISATION</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-sm-6">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453590/website/events/j2c/2018/jacob_iyfw6y.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">Kouassi Jacob Kra</h5>
                                            <h6>SOFTWARE ENGINEER</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/kouassi-jacob-kra-66958492/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453584/website/events/j2c/2018/christ_idea6b.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">Alexis C DJIDONOU</h5>
                                            <h6>CTO</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/alexis-christian-djidonou-bb7936113/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="team-resto" className="team-member-section ptb-50">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-7 col-md-8">
                                    <div className="section-heading text-center mb-5">
                                        <span className="text-uppercase color-secondary sub-title">L’EQUIPE</span>
                                        <h2>TEAM RESTAURATION</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-sm-6">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453586/website/events/j2c/2018/france_pbhhof.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">Marie France BROU</h5>
                                            <h6>Développeuse full stack</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/marie-france-brou-4228b9160/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="team-tech" className="team-member-section ptb-100">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-7 col-md-8">
                                    <div className="section-heading text-center mb-5">
                                        <span className="text-uppercase color-secondary sub-title">L’EQUIPE</span>
                                        <h2>TEAM TECH</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-sm-6">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453643/website/events/j2c/2018/yeo_iq24re.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">Soungalo YEO</h5>
                                            <h6>Consultant Java & Angular</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/soungalo-yeo-a68435135/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453593/website/events/j2c/2018/ghislain_ad2uwl.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">OUEI GUEU Ghislain</h5>
                                            <h6>Consultant 3D</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/ghislainouei/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453585/website/events/j2c/2018/amani_dykotj.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">Christian C Amani</h5>
                                            <h6>Consultant Java/JavaEE/Spring</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/christian-cyrille-amani-2b7b64112/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="team-spon" className="team-member-section pt-50 pb-100">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-7 col-md-8">
                                    <div className="section-heading text-center mb-5">
                                        <span className="text-uppercase color-secondary sub-title">L’EQUIPE</span>
                                        <h2>TEAM SPONSORING</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-sm-6">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453587/website/events/j2c/2018/gerard_liskf2.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">Gerard Konan</h5>
                                            <h6>Founder & CEO at AGILLY</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/gerardkonan/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453586/website/events/j2c/2018/ericzile_fzho64.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">Eric Zile Kouassi</h5>
                                            <h6>Directeur R & D chez Syca SAS</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/ericzilekouassi/?originalSubdomain=ci"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453621/website/events/j2c/2018/serge_ssd5a7.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">Komi Serge INNOCENT</h5>
                                            <h6>Senior Principal Software Engineer</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/komi-serge-innocent-425ba754/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="speaker" className="our-blog-section ptb-100 primary-bg">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="section-heading mb-5 text-white">
                                        <h2 className="text-white">Nos speakers</h2>
                                        <p>
                                            Ficipsunto blaut et ipsuntis Ci apitem quatem eossit dolor.Nectus il explis
                                            nos acestio est
                                            velest laborit veristo volorro quatemquiae.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-sm-6 pb-60">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453600/website/events/j2c/2018/panih_xgd7jz.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">KONE PANH</h5>
                                            <h6>Ingénieur</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/panh-kone-a25433207/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-6 pb-60 pb-60">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453602/website/events/j2c/2018/malick_kwrd2k.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">KONÉ M. SHOLA</h5>
                                            <h6>Fullstack Developer</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/malick-shola-kone/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-6 pb-60 pb-60">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453621/website/events/j2c/2018/serge_ssd5a7.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">SERGE KOMI</h5>
                                            <h6>Lead Software Developer</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/komi-serge-innocent-425ba754/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-6 pb-60">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453643/website/events/j2c/2018/yeo_iq24re.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">Soungalo YEO</h5>
                                            <h6>Consultant Java & Angular</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/soungalo-yeo-a68435135/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-6 pb-60">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453587/website/events/j2c/2018/gerard_liskf2.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">Gerard Konan</h5>
                                            <h6>Founder & CEO at AGILLY</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/gerardkonan/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-6 pb-60">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453602/website/events/j2c/2018/kouya_b1rd9j.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">KOUYA W. ARNOLD</h5>
                                            <h6>Consultant Fonctionnel Technique</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/arnoldkouya/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-6 pb-60">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453593/website/events/j2c/2018/franch_dakia_qv6aut.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">DAKIA FRANCK</h5>
                                            <h6>Creator, Web and Mobile Developer</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/franck-dakia/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-6 pb-60">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453595/website/events/j2c/2018/konan_y8farq.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">KONAN K. M. ALANE</h5>
                                            <h6>Data Manager</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/mack-alane-konan-9398ba142/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-6 pb-60">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453600/website/events/j2c/2018/koloma_ifr9cj.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">SORO KOLOMA</h5>
                                            <h6>Développeur FullStack</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/koloma-soro/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-6 pb-60">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453638/website/events/j2c/2018/hermance_koko_awvesj.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">HERMANCE KOKO</h5>
                                            <h6>Développeur FullStack</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.facebook.com/Kahl.Precious"><span
                                                    className="ti-facebook"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-6 pb-60">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453590/website/events/j2c/2018/kobon_zaxlcy.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">KOBON A. F.-XAVIER</h5>
                                            <h6>Webmaster</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/fkobon/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-6 pb-60">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453599/website/events/j2c/2018/madame_brou_srn0rt.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">NDRI F. EPSE BROU</h5>
                                            <h6>Developpeuse</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/n-dri-francine-epse-brou-a52974106/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-6 pb-60">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453587/website/events/j2c/2018/boni_wyqshv.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">BOLI EMMANUEL</h5>
                                            <h6>Ingénieur systèmes et réseaux</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/emmanuel-boli-b4231110a/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-6 pb-60">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453655/website/events/j2c/2018/doumbia_afosjn.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">DOUMBIA MOUSSA</h5>
                                            <h6>Dévelopeur</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/moussa-doumbia-450145106/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-6 pb-60">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453639/website/events/j2c/2018/moderand_l2q9nu.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">AMOUSSOU MODERAND</h5>
                                            <h6>Ethical Hacker</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/moderand-amoussou-561a0994/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-6 pb-60">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453593/website/events/j2c/2018/ghislain_ad2uwl.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">OUEI GUEU Ghislain</h5>
                                            <h6>Consultant 3D</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/ghislainouei/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-6 pb-60">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453632/website/events/j2c/2018/sahourey_tv6coo.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">KONAN SAHOUREY</h5>
                                            <h6>Expert-Consultant</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/sahourey-konan-adm-a-mba-itb-26552829/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-6">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453584/website/events/j2c/2018/christ_idea6b.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">Alexis C DJIDONOU</h5>
                                            <h6>CTO</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/alexis-christian-djidonou-bb7936113/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-6">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453616/website/events/j2c/2018/maruis_qqndgh.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">Marius Kwadyo</h5>
                                            <h6>Tech Video Producer</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/mariuskwadyo/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-sm-6">
                                    <div className="single-team-member position-relative">
                                        <div className="team-image">
                                            <img
                                                src="https://res.cloudinary.com/j2code/image/upload/v1647453586/website/events/j2c/2018/dexter_nye971.jpg"
                                                alt="Team Members"
                                                className="img-fluid rounded-circle"/>
                                        </div>
                                        <div
                                            className="team-info text-white rounded-circle d-flex flex-column align-items-center justify-content-center">
                                            <h5 className="mb-0">OUATTARA DEXTER</h5>
                                            <h6>Chief Product Officer</h6>
                                            <ul className="list-inline team-social social-icon mt-4 text-white">
                                                <li className="list-inline-item"><a
                                                    href="https://www.linkedin.com/in/dexter-ouattara-958a787a/"><span
                                                    className="ti-linkedin"/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                </div>
                <FooterSection noSubscription={true}/>
            </React.Fragment>
        );
    }

}

export default EventJ2c2018;