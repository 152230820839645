import React from 'react';
import { NavLink } from 'react-router-dom';

const NavBarAccount = () => {
    return (
        <div className="col-lg-4">
            <div className="card shadow-none mb-3 mb-lg-0">
                <div className="card-body">
                    <div className="list-group list-group-flush">
                        {/* <NavLink href="account-dashboard.html" className="list-group-item d-flex justify-content-between align-items-center bg-transparent">Dashboard <i className='bx bx-tachometer fs-5'></i></NavLink> */}
                        <NavLink to="/account/detail-account" className="list-group-item d-flex justify-content-between align-items-center bg-transparent">Détails du Compte <i className='bx bx-user-circle fs-5'></i></NavLink>
                        <NavLink to="/account/orders" className="list-group-item active d-flex justify-content-between align-items-center">Commandes <i className='bx bx-cart-alt fs-5'></i></NavLink>
                        {/* <NavLink href="account-addresses.html" className="list-group-item d-flex justify-content-between align-items-center bg-transparent">Addresses <i className='bx bx-home-smile fs-5'></i></NavLink> */}
                        {/* <NavLink href="account-payment-methods.html" className="list-group-item d-flex justify-content-between align-items-center bg-transparent">Payment Methods <i className='bx bx-credit-card fs-5'></i></NavLink> */}
                        {/* <NavLink href="#" className="list-group-item d-flex justify-content-between align-items-center bg-transparent">Logout <i className='bx bx-log-out fs-5'></i></NavLink> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavBarAccount;