import React, {useEffect, useState} from "react";
import {productCategoryConverter} from "../../../../../models/ProductCategory";
import {db} from "../../../../../firebase/firebase";

const CategoryHomeStore = () => {

    const [category, setCategory] = useState([]);

    const fetchCategory = async () => {
        db.collection("ProductCategories")
            .limit(3)
            .withConverter(productCategoryConverter)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const category = doc.data();
                    setCategory(arr => [...arr, category])
                });
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });
    }

    useEffect(async () => {
        await fetchCategory();
    }, [])

    return (
        <section className="py-4">
            <div className="container">
                <div className="d-flex align-items-center">
                    <h5 className="text-uppercase mb-0">NOS CATEGORIES</h5>
                    <a href="/store/all-categories" className="btn btn-light ms-auto rounded-0">Plus de categories
                        <i className='bx bx-chevron-right'></i></a>
                </div>
                <hr/>
                <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                    {category.map((data, i) => (<div className="col">
                        <div className="card rounded-0" key={i}>
                            <div className="row g-0 align-items-center">
                                <div className="col">
                                    <img height={100} width={100} src={data.urlPicture} className="img-fluid" alt=""/>
                                </div>
                                <div className="col">
                                    <div className="card-body">
                                        <h5 className="card-title text-uppercase">{data.name}</h5>
                                        <p className="card-text text-uppercase">A partir de {data.startingAt} XOF</p>
                                        <a href="src/components/Store/home/presentation/components/CategoryHomeStore#"
                                           className="btn btn-light btn-ecomm">Acheter maintenant</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>))}
                </div>
            </div>
        </section>);
}

export default CategoryHomeStore;