class ProductCategory {
    constructor(id, name, urlPicture, startingAt) {
        this.id = id;
        this.name = name;
        this.urlPicture = urlPicture;
        this.startingAt = startingAt;
    }

    toString() {
        return this.id + ', ' + this.name + ', ' + this.urlPicture + ', ' + this.startingAt;
    }
}

//Firestore data converter
export const productCategoryConverter = {
    toFireStore: function (productCategory) {
        return {
            id: productCategory.id,
            name: productCategory.name,
            urlPicture: productCategory.urlPicture,
            startingAt: productCategory.startingAt
        }
    },
    fromFirestore: function (snapshot, options) {
        const data = snapshot.data(options);
        return new ProductCategory(data.id, data.name, data.urlPicture, data.startingAt);
    }
};